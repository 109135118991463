import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const useAuth = (initial) => {
  const [isAuth, setIsAuth] = useState(initial);
  let navigate = useNavigate()


  const login = async () => {
    setIsAuth(true);
    // history.push("/home")
    return true
  }

  const logout = async () => {
    setIsAuth(false);
    return false
  }

  return [isAuth, login, logout]
}

export default useAuth
