import Button from '../buttonComponent/button'
import BottomModal from '../BottomModal/BottomModal'
import './card.css'
import { useState } from 'react'

const Card = ({ card, homescreen, MParkingIssues, vehicleDetails, requiredInfo, hasUserInfo, setSecondHome }) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [modalData, setModalData] = useState([])

  // const handleShow = (data) => {
  //   setDisplayModal(true)
  //   setModalData(data)
  // };


  return (
    <div className={`service-card introscreen-service-card ${homescreen ? 'homescreen-service-card' : ''}`}
      id={card.buttonText === 'Notify Issue Now' ? 'notify-card' : ''} >

      {card.buttonText !== 'Notify Issue Now' ? (
        <div className="service-card-left">
          <div className="card-icon">
            <img src={card.cardIcon} alt="person icon" />
          </div>
          <p className="card-heading">{card.cardHeading}</p> {/*Change onClick modal show for required user info Bottom Sheet */}
          <p className="card-detail">{card.cardDetail}</p>
        </div>
      ) : <></>}

      <div className="service-card-right">
        <Button
          buttonText={card.buttonText}
          buttonIcon={card.buttonIcon}
          buttonAlt={card.buttonAlt}
          buttonLink={card.buttonLink}
          linkComponent={card.linkComponent}
          homescreen={homescreen}
          displayModal={displayModal}
          setDisplayModal={setDisplayModal}
          conditionalModal={card.conditionalModal}
          MData={card.MData}
          setModalData={setModalData}
          requiredInfo={requiredInfo}
          hasUserInfo={hasUserInfo}
          emergency={card.emergency}
          intro={card.intro}
          setSecondHome={setSecondHome}
        />
      </div>
      <BottomModal
        displayModal={displayModal}
        setDisplayModal={setDisplayModal}
        modalData={modalData}
        MParkingIssues={MParkingIssues}
        vehicleDetails={vehicleDetails}
      />
    </div>
  )
}

export default Card
