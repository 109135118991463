/* eslint-disable */
import Header from "./components/header/Header"
import { useState, useEffect } from "react";
import './components/UserInfo/UserInfo.css'
import Footer from "./components/footer/Footer";
import { motion } from "framer-motion";

import { saveUser } from './api/Auth'
import { useNavigate } from 'react-router-dom';

const UserInfo = ({ setHeaderBack, setBackBtnText }) => {
  setHeaderBack(true)
  setBackBtnText('')
  const [disable, setDisable] = useState(true)
  const [username, setUsername] = useState("");
  const [useremail, setUseremail] = useState("");
  const [flatNo, setFlatNo] = useState('')
  // const [mobileNumber, setMobileNumber] = useState("");
  const [society, setSociety] = useState('')

  let navigate = useNavigate()

  const activeId = 3

  useEffect(() => {
    sessionStorage.getItem("user") && JSON.parse(sessionStorage.getItem("user")).userID ? navigate('/userinfo') : navigate('/')

    let userInfo = JSON.parse(sessionStorage.getItem('user'));
    setUsername(userInfo.name ? userInfo.name : '');
    setUseremail(userInfo.email ? userInfo.email : '');
    setFlatNo(userInfo.flatNumber ? userInfo.flatNumber : '');
    setSociety(userInfo.societyName ? userInfo.societyName : '')
  }, [])

  const updateUser = () => {
    let userInfo = JSON.parse(sessionStorage.getItem('user'));
    const userData = {
      sid: userInfo.userID,
      name: username,
      societyId: userInfo.societyId,
      contact: userInfo.contact,
      email: useremail,
      flatNumber: flatNo,
      role: userInfo.role
    }
    saveUser(userData)
      .then(res => {
        if (res) {
          let respUser = res.data;
          const user = {
            contact: respUser.contact,
            email: respUser.email,
            flatNumber: respUser.flatNumber,
            name: respUser.name,
            role: respUser.role,
            societyId: respUser.societyId,
            societyName: userInfo.societyName,
            token: userInfo.token,
            userID: userInfo.userID,
            authenticated: true
          }
          sessionStorage.setItem("user", JSON.stringify(user))
          navigate('/home')
          // sessionStorage.getItem("allVehicles") ? history.push("/home") : history.push("/addvehicle")
        }
      })

  }

  const validateForm = () => {
    (document.getElementById('user-name').value !== '') &&
      (document.getElementById('user-email').value !== '') &&
      (document.getElementById('user-flat-no').value !== '') ? setDisable(false) : setDisable(true)
    // (username !== '' && useremail !== '' && flatNo !== '') ? setDisable(false) : setDisable(true)
  }

  const pageVariants = {
    initial: {
      // opacity: 0,
      x: "100vw",
      // scale: 0.8
    },
    in: {
      // opacity: 1,
      x: 0,
      // scale: 1
    },
    out: {
      // opacity: 0,
      x: "-100vw",
      // scale: 1.2
    }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.3
  };

  return (
    <>
      <motion.div
        initial='initial'
        animate='in'
        exit='out'
        variants={pageVariants}
        transition={pageTransition}
        className='homescreen-container'>
        <div className="user-info-wrapper form-wrapper">

          <form className="login-form d-flex flex-column align-items-center user-infoForm user-form" onChange={validateForm}>
            <p className='form-heading'>
              Enter User Detail
            </p>
            <div className="input-fields d-flex flex-column align-items-start">
              <label htmlFor="user-name">Full Name</label>
              <input type="text" placeholder={username ? username : 'Enter Full Name'} className='login-input' id='user-name' autoComplete="off" value={username ? username : ""} onChange={(e) => setUsername(e.target.value)} />
            </div>
            <div className="input-fields d-flex flex-column align-items-start">
              <label htmlFor="user-email">Email Id</label>
              <input type='email' placeholder={useremail ? useremail : 'Enter Email ID'} className='login-input' id='user-email' autoComplete="off" value={useremail ? useremail : ""} onChange={(e) => setUseremail(e.target.value)} />
            </div>
            <div className="input-fields d-flex flex-column align-items-start">
              <label htmlFor="user-flat-no">Flat No.</label>
              <input type='text' placeholder={flatNo ? flatNo : 'Enter Flat No.'} className='login-input' id='user-flat-no' autoComplete="off" value={flatNo ? flatNo : ""} onChange={(e) => setFlatNo(e.target.value)} />
            </div>
            {/* <div className="input-fields d-flex flex-column align-items-start">
            <label htmlFor="#user-mobile-number">Mobile No.</label>
            <input type="number" placeholder='8989786489' className='login-input' id='user-mobile-number' autoComplete="off" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
          </div> */}
            {/* <div className="input-fields d-flex flex-column align-items-start" style={{ pointerEvents: "none" }}>
            <label htmlFor="user-society">Society</label>
            <input type='text' placeholder={society ? society : 'Society Name'} className='login-input' id='user-society' disabled autoComplete="off" value={society ? society : ""} />
          </div> */}
          </form>
        </div>
        {/* <Footer activeId={activeId} /> */}
      </motion.div>
      <div className='btn-fixed' >
        <button type="submit" onClick={updateUser} className="primary-submit-btn d-flex justify-content-center user-info-btn" disabled={disable}><p>Done</p></button>
      </div>
    </>
  )
}

export default UserInfo
