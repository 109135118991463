import axios from 'axios'
import { getAllMyVehicles } from './VehiclesApi'

const headers = {
  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
  'Content-Type': 'application/json',
  "Access-Control-Allow-origin": "*"
}

var userinfo = {
  userID: '',
  name: '',
  contact: '',
  email: '',
  flatNumber: '',
  societyId: '',
  societyName: '',
  role: '',
  token: '',
  vehicleDetails: []
}

// User Login ------
export const userLogin = async (contact) => {

  const loginData = JSON.stringify({
    contact: contact,
    hashKey: "POeSCSWAn5p"
  })

  let loginResponse
  const societyDetails = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {}

  const qrSociety = {

    societyName: societyDetails.societyName,
    societyId: societyDetails.societyId
  }

  await axios.post(`${process.env.REACT_APP_BASE_URL}/user/login`, loginData, { headers })
    .then(res => {
      loginResponse = res.data.data;

      userinfo = {
        userID: loginResponse.sid,
        name: loginResponse.name,
        contact: loginResponse.contact,
        email: loginResponse.email,
        flatNumber: loginResponse.flatNumber,
        societyId: loginResponse.societyId ? loginResponse.societyId : qrSociety.societyId,
        societyName: loginResponse.societyName ? loginResponse.societyName : qrSociety.societyName,
        role: loginResponse.role,
        vehicleDetails: [],
        authenticated: false
      }
    })
    .catch(err => { console.log("Failed... ", err) })

  return loginResponse;
}

// Verify OTP
export const verifyOtp = async (contact, otp) => {
  let otpResponse;

  // let auth = {
  //   hasVehicle: false,
  //   existingUser: false  // For the users having User Info flow complete
  // }

  const otpData = JSON.stringify({
    mobileNumber: contact,
    otp: otp,
  })

  await axios.post(`${process.env.REACT_APP_BASE_URL}/verify`, otpData, { headers })
    .then(res => {
      if (res) {
        otpResponse = res.data.data
        userinfo.token = otpResponse.token
        window.sessionStorage.setItem("user", JSON.stringify(userinfo))
        const saveUserData = {
          sid: userinfo.userID,
          name: userinfo.name,
          societyId: userinfo.societyId,
          contact: userinfo.contact,
          email: userinfo.email,
          flatNumber: userinfo.flatNumber,
          role: userinfo.role,
          authenticated: false,
        }
        saveUser(saveUserData)
          .then(res => console.log("USer Saved"))
      }
    })
    .catch(err => console.log(err))
  return userinfo

}


// Resend OTP
export const resendOtp = async (contact) => {

  let loginResponse

  const loginData = JSON.stringify({
    contact: contact,
    hashKey: "9LhI3RZ9f6z"
  })

  await axios.post(`${process.env.REACT_APP_BASE_URL}/user/resendOtp`, loginData, { headers })
    .then(res => {
      loginResponse = res.data;
    })
    .catch(err => { console.log("Failed... ", err) })

  return loginResponse
}

// check qr status
export const checkQrStatus = async (contact) => {
  let userToken = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).token : ''

  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${userToken}`
  }
 
  let loginResponse
  let qr_id = sessionStorage.getItem("qr_id")
  
  await axios.get(`${process.env.REACT_APP_BASE_URL}/vehicle/checkQrStatus?qr_id=${qr_id}&contact_number=${contact}`, { headers })
    .then(res => {
      loginResponse = res.data
    })
    .catch(err => { console.log("Failed... ", err) })
  return loginResponse
}


// Save user
export const saveUser = async (userData) => {
  let userToken = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).token : ''
  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${userToken}`
  }
  let resUser;

  await axios.post(`${process.env.REACT_APP_BASE_URL}/user/save`, JSON.stringify(userData), { headers })
    .then(res => {
      resUser = res.data
    })
    .catch(err => console.log(err))

  return resUser;
}

// Logout User
export const logoutUser = async () => {
  let user = JSON.parse(sessionStorage.getItem("user"))
  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${user.token}`
  }
  let response;

  await axios.post(`${process.env.REACT_APP_BASE_URL}/user/${user.userID}/logout`, {}, { headers })
    .then(res => {
      if (res) {
        // response = res.data;
        let qr_id = sessionStorage.getItem('qr_id')
        let urlParams = JSON.parse(sessionStorage.getItem('url_params'))
        sessionStorage.clear();
        window.location.href = `/?qr=${urlParams.societyId}&name=${urlParams.societyName}&qr_id=${qr_id}`
      }
    })
    .catch(err => console.log(err))
  return response;
}


