import { useState, useEffect } from 'react'
// import AddVehicle from './components/AddVehicle/AddVehicle';
import CarCard from './components/Car-CardComponent/CarCard';
import Header from './components/header/Header'
import './components/MyVehicles/MyVehicles.css'
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { getAllMyVehicles } from './api/VehiclesApi';
import AddBikeButton from './components/AddBikeButton';
import AddCarButton from './components/AddCarButton';


const MyVehicles = ({ setHeaderBack, setBackBtnText }) => {
  setHeaderBack(true)
  setBackBtnText('My Vehicles')
  const [vehicleData, setVehicleData] = useState([])
  const [carData, setCarData] = useState([])
  const [bikeData, setBikeData] = useState([])
  let navigate = useNavigate()

  useEffect(() => {
    var myVehicles = JSON.parse(sessionStorage.getItem('myVehicles'))
    let vehicleDetails = []

    getAllMyVehicles()
      .then(res => {
  
        const tempCarData = []
        res.data[1].vehicles.forEach(vehicle => {
          const carObj = {
            type: 'FOUR_WHEELER',
            id: vehicle.sid,
            CHeading: vehicle.model,
            CSubheading: vehicle.registrationNumber,
            CExtraButton: false,
            CRightPrice: false,
            CRightExtraButton: false,
            CPriceCenter: false,
            CRightBin: true,
            MData: {
              MHeading: 'Delete' + ' ' + `"${vehicle.model}"`,
              carDeleteId: vehicle.registrationNumber,
              MSubheading: 'Are you sure, you want to delete this vehicle?',
              MButtonText1: 'Leave',
              MButtonText2: 'Yes, Delete'
            },
          }
          tempCarData.push(carObj)
        })
        setCarData(tempCarData)

        //set bike
        let tempBikeData = []
        res.data[0].vehicles.forEach(vehicle => {
          const carObj = {
            type: 'TWO_WHEELER',
            id: vehicle.sid,
            CHeading: vehicle.model,
            CSubheading: vehicle.registrationNumber,
            CExtraButton: false,
            CRightPrice: false,
            CRightExtraButton: false,
            CPriceCenter: false,
            CRightBin: true,
            MData: {
              MHeading: 'Delete' + ' ' + `"${vehicle.model}"`,
              carDeleteId: vehicle.registrationNumber,
              MSubheading: 'Are you sure, you want to delete this vehicle?',
              MButtonText1: 'Leave',
              MButtonText2: 'Yes, Delete'
            },
          }
          tempBikeData.push(carObj)
        })
        setBikeData(tempBikeData)

      })


  }, [])

  const handleRedirect = (page) => {
    navigate(page)
  }

  const pageVariants = {
    initial: {
      // opacity: 0,
      x: "100vw",
      // scale: 0.8
    },
    in: {
      // opacity: 1,
      x: 0,
      // scale: 1
    },
    out: {
      // opacity: 0,
      x: "-100vw",
      // scale: 1.2
    }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.3
  };

  return (
    <motion.div
      initial='initial'
      animate='in'
      exit='out'
      variants={pageVariants}
      transition={pageTransition}
      className='homescreen-container'>
      <div className="vehicle-container">

        <div className='car-container'>
          <div className="carpackage-header d-flex justify-content-between">
            <h3 className="section-heading">Cars</h3>
           
          </div>
          <div className="vehicle-wrapper d-flex flex-column">
            {
                carData.map((car) => (
                  <CarCard
                    vehicleComp={true}
                    car={car}
                    key={car.id}
                  // deleteCar={deleteCar}
                  />
                ))
            }
          </div>
          <div className='bikes-container'>
            <div className="carpackage-header d-flex justify-content-between">
              <h3 className="section-heading">Bikes</h3>
           
            </div>
            <div className="vehicle-wrapper d-flex flex-column">
              {
                  bikeData.map((car) => (
                    <CarCard
                      vehicleComp={true}
                      car={car}
                      key={car.id}
                    // deleteCar={deleteCar}
                    />
                  ))
              }
            </div>
          </div>

        </div>
        {/* <AddVehicle type={'car'} /> */}
      </div>

    </motion.div>
  )
}

export default MyVehicles
