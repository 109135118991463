import React from 'react'
import { Link } from "react-router-dom";
import Whatsapp from '../../assets/icons/whatsapp.svg'


const Button = ({ buttonText,
  buttonIcon,
  buttonAlt,
  buttonLink,
  linkComponent,
  homescreen,
  displayModal,
  setDisplayModal,
  conditionalModal,
  MData,
  setModalData,
  requiredInfo,
  hasUserInfo,
  emergency,
  intro,
  setSecondHome
}) => {

  const handleShow = (data) => {
    setDisplayModal(true)
    setModalData(data)
    // console.log("MODAL", data);
  };

  // console.log(intro);
  return (
    <>
      {
        conditionalModal ? (
          <button className={`service-card-btn ${homescreen ? 'homescreen-btn' : ''}`} onClick={() => { hasUserInfo ? handleShow(MData) : emergency ? handleShow(MData) : handleShow(requiredInfo) }}>
            {buttonText}
            <span className='cardBtn-icon' > <img src={buttonIcon} alt={buttonAlt} /></span >
          </button>
        ) : (
          <Link to={buttonLink} onClick={intro ? () => setSecondHome(true) : null}>
            <button className={`service-card-btn ${homescreen ? 'homescreen-btn' : ''}`}>
              {buttonText}
              {
                buttonText !== 'Notify Issue Now' ?
                  <span className='cardBtn-icon'><img src={buttonIcon} alt={buttonAlt} /></span>
                  :
                  <img src={Whatsapp} alt="whatsapp-icon" className='whatsapp-icon' />
              }
            </button>
          </Link>
        )
      }
    </>
  )
}

export default Button
