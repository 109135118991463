//Dependencies/Components
import { useEffect, useState } from 'react'
import LoginOwner from "./LoginOwner";
import LoginOtp from "./LoginOtp"

import { logoutUser } from './api/Auth'

//CSS
import './App.css';
import HomeScreen from "./HomeScreen";
import Sanitisation from "./Sanitisation";
import Subscription from "./Subscription";
import MyBookings from "./MyBookings";

// Routing
import {
   useLocation,
   Routes,
   Route,
   Redirect,
   Navigate
} from 'react-router-dom'
import IntroScreen from "./IntroScreen";
import SecondHomeScreen from "./SecondHomeScreen";
import MyProfile from "./MyProfile";
import RaisedIssues from "./RaisedIssues";
import MyVehicles from "./MyVehicles";
import UserInfo from "./UserInfo";
import VehicleInfo from "./VehicleInfo";
import { AnimatePresence } from "framer-motion";
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import SelectVehicleType from './selectVehicleType';
import BikeInfo from './BikeInfo';

// export let userExists = false;

function App() {
   const [userToken, setUserToken] = useState('')
   const [headerBack, setHeaderBack] = useState(false)
   const [backBtnText, setBackBtnText] = useState('')
   const [secondHome, setSecondHome] = useState(false)

   const location = useLocation()

   const [societyName, setSocietyName] = useState('')
   const [redirectedUser, setRedirectedUser] = useState(true)
   const qrDataParams = new URLSearchParams(useLocation().search)
   
   const elProps = {
      setHeaderBack: setHeaderBack,
      setBackBtnText: setBackBtnText
   }
   
   useEffect(() => {
      // cfPayment();
      let societyId = ''
      qrDataParams.get('society_id') ? societyId = qrDataParams.get('society_id') : societyId = qrDataParams.get('qr')
      if (redirectedUser) {
         if (qrDataParams.get('qr_id')) {
            const userInfo = {
               userID: '',
               name: '',
               contact: '',
               email: '',
               flatNumber: '',
               societyId: societyId,
               societyName: qrDataParams.get('name') ? qrDataParams.get('name') : '',
               role: '',
               token: '',
               vehicleDetails: [],
               qr_id: qrDataParams.get('qr_id') ? qrDataParams.get('qr_id') : '',
            }
            sessionStorage.setItem("user", JSON.stringify(userInfo));
            sessionStorage.setItem('qr_id', qrDataParams.get('qr_id') ? qrDataParams.get('qr_id') : '')
            let urlParams = { societyId, societyName: userInfo.societyName }
            sessionStorage.setItem('url_params', JSON.stringify(urlParams))
            
            setSocietyName(userInfo.societyName)
            setRedirectedUser(false);
         }
      }

      const token = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).token : ''
      setUserToken(token);
   }, [])

   return (
      <div className="App">
         {
             (
                <Header headerBack={headerBack} backText={backBtnText} secondHome={secondHome} society={
                   societyName ? societyName : JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).societyName : ""}
                />
             )
         }
         <main>
            <AnimatePresence exitBeforeEnter>
               <Routes>
                  <Route path="/" element={JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).authenticated ? <HomeScreen setHeaderBack={setHeaderBack} /> : <IntroScreen setSecondHome={setSecondHome} setHeaderBack={setHeaderBack} /> : <IntroScreen setSecondHome={setSecondHome} setHeaderBack={setHeaderBack} /> } />
                  {/* <Route path="/" element={userToken ? <HomeScreen setHeaderBack={setHeaderBack} /> : <IntroScreen setSecondHome={setSecondHome} setHeaderBack={setHeaderBack} />} /> */}
                  <Route path="/login" element={<LoginOwner {...elProps} />} />
                  <Route path="/verify" element={<LoginOtp {...elProps} />} />
                  <Route path='/select-vehicle-type' element={<SelectVehicleType setHeaderBack={setHeaderBack} setBackBtnText={setBackBtnText}  />} />
                  <Route path="/addcar" element={<VehicleInfo setHeaderBack={setHeaderBack} setBackBtnText={setBackBtnText} />} />
                  <Route path="/addbike" element={<BikeInfo setHeaderBack={setHeaderBack} setBackBtnText={setBackBtnText} />} />
                  <Route path="/home" element={<HomeScreen setHeaderBack={setHeaderBack} />} />
                  <Route path="/SecondHomeScreen" element={<SecondHomeScreen setSecondHome={setSecondHome} />} />
                  <Route path="/packages" element={<Sanitisation setHeaderBack={setHeaderBack} setBackBtnText={setBackBtnText} />} />
                  <Route path="/subscription" element={
                     <Subscription setHeaderBack={setHeaderBack} setBackBtnText={setBackBtnText} />
                  } />
                  <Route path="/mybookings" element={
                     <MyBookings setHeaderBack={setHeaderBack} setBackBtnText={setBackBtnText} />
                  } />
                  <Route path="/myprofile" element={
                     <MyProfile setHeaderBack={setHeaderBack} setBackBtnText={setBackBtnText} />
                  } />
                  <Route path="/myvehicles" element={
                     <MyVehicles setHeaderBack={setHeaderBack} setBackBtnText={setBackBtnText} />
                  } />
                  <Route path="/userinfo" element={
                     <UserInfo setHeaderBack={setHeaderBack} setBackBtnText={setBackBtnText} />
                  } />
                  <Route path="/raisedissues" element={
                     <RaisedIssues setHeaderBack={setHeaderBack} setBackBtnText={setBackBtnText} />
                  } />
                  <Route path="/serviitnc" element={() => {
                     window.location.href = 'https://servii-html.s3.ap-south-1.amazonaws.com/pp.html'
                  }} />
                  <Route path="/serviipp" element={() => {
                     window.location.href = 'https://servii-html.s3.ap-south-1.amazonaws.com/pp.html'
                  }} />
               </Routes>
            </AnimatePresence>
         </main>
      </div>
   );
}

export default App;
