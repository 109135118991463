// import carCardIcon from '../../assets/icons/car-card-icon.svg'
import { useEffect, useState } from 'react'
import carCardSmallIcon from '../../assets/icons/car-card-small-icon.svg'
import bikeCardSmallIcon from '../../assets/icons/bike-card-small-icon.svg'
import '../CarBoxComponent/CarBox.css'

const IssueCarBox = ({ carData, setVehicleSelected, vehicleType }) => {

  return (
    <>
      {
        carData.map((car) => (
          <label id={`card-container-${car.id}`} htmlFor={`car-check-${car.id}`} className='checkbox-label' style={{ padding: "0" }} key={car.id}>
            <input type="radio" name="car-box" id={`car-check-${car.id}`} onChange={() => setVehicleSelected({ vehicleId: car.id, vehicleName: car.carName, vehicleNumber: car.carNumber })} />
            <span className="custom-checkmark" style={{ left: "auto", right: "8px", top: "8px" }}></span>
            <div className='car-box-container d-flex flex-column position-relative' id={`car-box-${car.id}`}>
              <div className="icon-container">
                <div className="car-icon d-flex justify-content-center">
                  <img src={carCardSmallIcon} alt="" />
                </div>
              </div>
              <div className="car-details">
                <h6 className="car-name">
                  {car.carName}
                </h6>
                <p className="car-number">
                  {car.carNumber}
                </p>
              </div>
            </div>
            <style jsx="true">
              {`
                #car-check-${car.id}:checked ~ #car-box-${car.id} {
                  background: #FFF8EA;
                  border: 1px solid #2E2F2F
                }
              `}
            </style>
          </label>
        ))
      }
    </>
  )
}

export default IssueCarBox
