import { useState, useEffect, useCallback } from 'react'
import { getParkingIssues } from './api/IssuesApi'
import { getVehicleByType } from './api/VehiclesApi'
import { motion } from 'framer-motion';

import { useNavigate } from 'react-router-dom';

//Images
import sanitisationIcon from './assets/icons/sanitisation-icon.svg'
import cardCaricon from "./assets/icons/car_yellow_fill.svg"
import parkingIcon from './assets/icons/parking-icon.svg'
import btnArrowIcon from "./assets/icons/ArrowFatLinesRight.svg"
import callIcon from './assets/icons/call-icon.svg'
import sendIcon from './assets/icons/send-icon.svg'

//Dependencies
import Card from "./components/cardComponents/Card";

//CSS
import './components/HomeScreen/HomeScreen.css'
import Banner from './components/Banner/Banner';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';


const HomeScreen = ({ setHeaderBack }) => {
  setHeaderBack(false)
  const [parkingIssues, setParkingIssues] = useState([])
  const [hasUserInfo, setHasUserInfo] = useState(false)
  const [myVehicles, setMyVehicles] = useState([])
  // const [vehicleSelected, setVehicleSelected] = useState({ vehicleId: '', vehicleName: '', vehicleNumber: '' })
  const activeId = 2

  let userDetails = JSON.parse(sessionStorage.getItem("user"));
  let navigate = useNavigate()

  const homescreen = true;

  // const userInfoLink = (page) => {
  //   let pageLink
  //   hasUserInfo === true ? pageLink = page : pageLink = '/userinfo'
  //   return pageLink
  // }

  const serviceDetails = [
    {
      id: 1,
      cardIcon: sanitisationIcon,
      cardHeading: 'Daily Car Cleaning Service',
      buttonText: 'Subscribe',
      buttonAlt: 'Arrow Icon',
      buttonIcon: btnArrowIcon,
      buttonLink: hasUserInfo ? '/packages' : null,
      conditionalModal: hasUserInfo ? false : true,
      emergency: false,
    },
    {
      id: 2,
      cardIcon: cardCaricon,
      cardHeading: 'Emergency Breakdown',
      buttonText: 'Call Now',
      buttonAlt: 'Phone Icon',
      buttonIcon: callIcon,
      conditionalModal: true,
      emergency: true,
      MData: {
        MHeading: 'Emergency Breakdown',
        MSubheading: 'We are just a call away. Till, we automate this. 🙂',
        MButtonText: 'Call Now',
        MCheckBox: false,
        callLink: 'tel:+918123310356',
      }
    },
    {
      id: 3,
      cardIcon: parkingIcon,
      cardHeading: 'Issue With The Parking?',
      buttonText: 'Inform Now',
      buttonAlt: 'Paper Plane Icon',
      buttonIcon: sendIcon,
      conditionalModal: true,
      emergency: false,
      MData: {
        MHeading: 'Issue With The Parking?',
        MSubheading: 'Please select your issues and raise it for the association',
        MButtonText: 'Send Now',
        MCheckBox: true,
      }
    },
  ]

  const requiredInfo = {
    requiredInfo: true,
    MHeading: 'Required User Info ✍️',
    MSubheading: 'Provide required details before using any service.',
    MButtonText: 'Proceed'
  }

  const homeScreenApis = useCallback(() => {

    if (!sessionStorage.getItem("parkingIssues")) {
      let allParkingIssues = []
      getParkingIssues()
        .then(res => {
          if (res) {
            let issues = []
            issues = res.data
            issues.forEach(issue => {
              const parkingIssues = {
                id: issue.sid,
                label: issue.name
              }
              allParkingIssues.push(parkingIssues)
            });
            sessionStorage.setItem("parkingIssues", JSON.stringify(allParkingIssues));
            setParkingIssues(allParkingIssues);
          }
        })
    }

    // Get Vehicle by type
    //if (!sessionStorage.getItem("myVehicles")) {
    getVehicleByType("FOUR_WHEELER")
      .then((res) => {
        if (res) {
          let carBoxDetails = []
          res.data.forEach(vehicle => {
            const vehicleDetails = {
              id: vehicle.sid,
              carName: vehicle.model,
              carNumber: vehicle.registrationNumber
            }
            carBoxDetails.push(vehicleDetails);
          })
          sessionStorage.setItem('myVehicles', JSON.stringify(carBoxDetails))
          setMyVehicles(carBoxDetails);
        }
      })
    getVehicleByType("TWO_WHEELER")
      .then((res) => {
        if (res) {
          let bikeBoxDetails = []
          res.data.forEach(vehicle => {
            const vehicleDetails = {
              id: vehicle.sid,
              carName: vehicle.model,
              carNumber: vehicle.registrationNumber
            }
            bikeBoxDetails.push(vehicleDetails);
          })
          sessionStorage.setItem('myBikes', JSON.stringify(bikeBoxDetails))
          setMyVehicles(prev => [...prev, ...bikeBoxDetails])
        }
      })
    //}

  }, [])

  useEffect(() => {
    if (sessionStorage.getItem("myVehicles")) {
      setMyVehicles(JSON.parse(sessionStorage.getItem("myVehicles")))
    }
    if (sessionStorage.getItem("parkingIssues")) {
      setParkingIssues(JSON.parse(sessionStorage.getItem("parkingIssues")))
    }
       sessionStorage.getItem("user") && JSON.parse(sessionStorage.getItem("user")).authenticated && JSON.parse(sessionStorage.getItem("user")).name ? navigate('/home') : navigate('/')
    // sessionStorage.getItem("user") && JSON.parse(sessionStorage.getItem("user")).name ? navigate('/home') : navigate('/userinfo')
  }, [])

  useEffect(() => {
    homeScreenApis()
    if (sessionStorage.getItem("user") && JSON.parse(sessionStorage.getItem("user")).name) {
      setHasUserInfo(true)
    }
    sessionStorage.removeItem("packageSelected")
  }, [homeScreenApis])

  const pageVariants = {
    initial: {
      // opacity: 0,
      x: "100vw",
      // scale: 0.8
    },
    in: {
      // opacity: 1,
      x: 0,
      // scale: 1
    },
    out: {
      // opacity: 0,
      x: "-100vw",
      // scale: 1.2
    }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.3
  };


  // console.log(modalData);
  return (
    <>
      <motion.div
        initial='initial'
        animate='in'
        exit='out'
        variants={pageVariants}
        transition={pageTransition}
        className='homescreen-container'>

        {/* <Header headerBack={false} society={userDetails ? userDetails.societyName : ''} /> */}
        <div className="homescreen-wrapper">
          <Banner profile={false} />
          <div className="services-user homescreen-services">
            <p>Services</p>
            {
              serviceDetails.map((card) => (
                <Card
                  card={card}
                  key={card.id}
                  homescreen={homescreen}
                  MParkingIssues={parkingIssues}
                  vehicleDetails={myVehicles}
                  requiredInfo={requiredInfo}
                  hasUserInfo={hasUserInfo}
                />
              ))
            }
          </div>
        </div>
      </motion.div>
      <Footer activeId={activeId} />
    </>
  )
}

export default HomeScreen
