import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function AddCarButton() {

    let navigate = useNavigate()

    return (
        <button className='select_vehicle_type_container__button' onClick={() => navigate('/addcar')}>
            <img src='/add-car.svg' />
            <div className='d-flex align-items-center button_right'>
                <p>Add car</p>
                <img src='/arrow-right.svg' />
            </div>
        </button>
    )
}
