import axios from 'axios'

// Get Parking Issues [ primary user ]
export const getParkingIssues = async () => {
  let userToken = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).token : ''
  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${userToken}`
  }
  let responseIssues;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/issues/parking`, { headers })
    .then(res => { responseIssues = res.data })
    .catch(err => console.log(err))

  return responseIssues

}

// Get Vehicle Issues [ secondary User ]
export const getVehicleIssues = async () => {
  let responseIssues;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/issues/vehicle`)
    .then((res) => {
      responseIssues = res.data
    })
    .catch(err => console.log(err))

  return responseIssues
}

// Post Vehicle Issue [ sec user ]
export const postVehicleIssue = async (issueBody) => {
  let responseIssues;

  const params = new URLSearchParams();
  params.append('issue_id', issueBody.issue_id);
  params.append('vehicle_id', issueBody.vehicle_id);
  params.append('reporter_contact_number', issueBody.reporter_contact_number);

  await axios.post(`${process.env.REACT_APP_BASE_URL}/issues/vehicle/add`, params)
    .then((res) => {
      responseIssues = res.data
    })
    .catch(err => console.log(err))

  return responseIssues
}

// Post parking Issue [ primary user ]
export const postParkingIssue = async (vehicleId) => {
  let userToken = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).token : ''
  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/x-www-form-urlencoded',
    "Authorization": `Bearer ${userToken}`
  }
  let responseIssues;

  let issueBody = {
    issue_id: JSON.parse(sessionStorage.getItem("selectedPI")),
    vehicle_id: vehicleId
  }

  const params = new URLSearchParams();
  issueBody.issue_id.forEach((issue) => {
    params.append('issue_id[]', issue);
  })
  params.append('vehicle_id', issueBody.vehicle_id);

  await axios.post(`${process.env.REACT_APP_BASE_URL}/issues/parking/add`, params, { headers })
    .then((res) => {
      responseIssues = res.data
      console.log('ParkingIssues logged', responseIssues);
    })
    .catch(err => console.log(err))

  return responseIssues
}

// Get My Parking Issues
export const getMyParkingIssues = async (issuesStatus) => {
  let userToken = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).token : ''
  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${userToken}`
  }
  let responseIssues;

  // INPROCESS | RESOLVED | NEW

  await axios.get(`${process.env.REACT_APP_BASE_URL}/myissues/${issuesStatus}`, { headers })
    .then((res) => {
      responseIssues = res.data
    })
    .catch(err => console.log(err))

  return responseIssues
}

