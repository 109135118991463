import axios from 'axios'

// Get Vehicle by Type
export const getVehicleByType = async (vehicleType) => {

  // Types: FOUR_WHEELER | TWO_WHEELER
  const userToken = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")).token : ""
  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${userToken}`
  }

  let vehicleData

  await axios.get(`${process.env.REACT_APP_BASE_URL}/myVehicles`, { params: { type: vehicleType }, headers })
    .then(res => {
      vehicleData = res.data;
    })
    .catch(err => console.log(err))

  return vehicleData
}

export const getAllMyVehicles = async () => {

  const userToken = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")).token : ""
  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${userToken}`
  }

  let vehicleData

  await axios.get(`${process.env.REACT_APP_BASE_URL}/myVehicles/all`, { headers })
    .then(res => {
      vehicleData = res.data;
    })
    .catch(err => console.log(err))

  return vehicleData
}

// Save Vehicle
export const saveVehicle = async (vehicleData) => {
  const userToken = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")).token : ""
  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${userToken}`
  }
  
  // vehicleData = {
  //   model: "Maruti 900",
  //   registrationNumber: "Ap2134567",
  //   fuelType: "PETROL",
  //   vehicleType: "FOUR_WHEELER",
  //   parkingLot: "ok",
  //   parkingArea: "heaven",
  //   userId: "56582e21-a4b2-4228-bec4-76c80d9c74e5"
  // }

  // const vehicle = JSON.stringify(vehicleData)

  let responseData
  let qr_id = sessionStorage.getItem("qr_id")

  if(!qr_id) return
  await axios.post(`${process.env.REACT_APP_BASE_URL}/vehicle/save/${qr_id}`, JSON.stringify(vehicleData), { headers })
    .then(res => {
      responseData = res.data;
    })
    .catch(err => console.log(err))

  return responseData

}


// Save Bike
export const saveBike = async (vehicleData) => {
  const userToken = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")).token : ""
  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${userToken}`
  }

  let responseData
  let qr_id = sessionStorage.getItem("qr_id")
  if(!qr_id) return
  await axios.post(`${process.env.REACT_APP_BASE_URL}/vehicle/save/${qr_id}`, JSON.stringify(vehicleData), { headers })
    .then(res => {
      responseData = res.data;
    })
    .catch(err => console.log(err))

  return responseData
}

// Verify vehicle
export const verifyVehicle = async (regNumber) => {
  let vehicleData
  let regNumberTrimmed = regNumber.replace(/ /g, '')
  await axios.get(`${process.env.REACT_APP_BASE_URL}/vehicle/verify`, { params: { registrationNo: regNumberTrimmed } })
    .then(res => {
      vehicleData = res.data;
    })
    .catch(err => console.log(err))

  return vehicleData
}

// Get Standard Vehicle (vehicle list)
export const standardVehicleList = async () => {
  let userToken = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).token : ''
  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${userToken}`
  }
  let vehicleData

  await axios.post(`${process.env.REACT_APP_BASE_URL}/standardVehicles?type=FOUR_WHEELER`, JSON.stringify(''), { headers })
    .then(res => {
      vehicleData = res.data;
    })
    .catch(err => console.log(err))

  return vehicleData
}

export const standardBikeList = async () => {
  let userToken = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).token : ''
  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${userToken}`
  }
  let vehicleData

  await axios.post(`${process.env.REACT_APP_BASE_URL}/standardVehicles?type=TWO_WHEELER`, JSON.stringify(''), { headers })
    .then(res => {
      vehicleData = res.data;
    })
    .catch(err => console.log(err))

  return vehicleData
}
