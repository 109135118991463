import serviiLogo from "../../servii-logo.svg"
import { Link, useNavigate } from "react-router-dom";
import backArrow from '../../assets/icons/back-arrow.svg'
import "./Header.css"
import locationIcon from '../../assets/icons/location-icon.svg'

const Header = (props) => {
  const { society, headerBack, backText, secondHome } = props
  let navigate = useNavigate()

  return (
    <>
      {
        headerBack ? (
          <header className='header d-flex justify-content-between align-items-center'>
            <div className="back-btn d-flex flex-row align-items-center" onClick={() => navigate(-1)}>
              <div className="back-arrow d-flex align-items-center">
                <img src={backArrow} alt="back" />
              </div>
              <p>{backText}</p>
            </div>
          </header>
        ) : (
          <header className={`header d-flex justify-content-between ${secondHome ? 'secondHome' : ('')}`}>
            <div className="header-left"><Link to={'/'}><img src={serviiLogo} alt="Servii Logo" /></Link></div>
            {
              society ? (
                <div className="header-right d-flex align-items-center">
                  <div className="location-icon">
                    <img src={locationIcon} alt="" />
                  </div>
                  {society}
                </div>
              ) : <></>
            }
          </header>
        )
      }
    </>
  )
}

export default Header