import React from 'react'
import './components/SelectVehicleType/selectVehicleType.css'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import './components/VehicleInfo/VehicleInfo.css'

import Header from './components/header/Header';
// import arrowDown from './assets/icons/down-arrow.svg'
import { saveVehicle, standardVehicleList } from './api/VehiclesApi'
import BottomModal from './components/BottomModal/BottomModal'
import AddCarButton from './components/AddCarButton';
import AddBikeButton from './components/AddBikeButton';

export default function SelectVehicleType({ setHeaderBack, setBackBtnText }) {

    setBackBtnText('Add vehicle')
    setHeaderBack(true)
    let navigate = useNavigate()

    const pageVariants = {
        initial: {
            // opacity: 0,
            x: "100vw",
            // scale: 0.8
        },
        in: {
            // opacity: 1,
            x: 0,
            // scale: 1
        },
        out: {
            // opacity: 0,
            x: "-100vw",
            // scale: 1.2
        }
    };

    const pageTransition = {
        type: "tween",
        ease: "anticipate",
        duration: 0.3
    };

    return (
        <motion.div
            initial='initial'
            animate='in'
            exit='out'
            variants={pageVariants}
            transition={pageTransition}
            className='select_vehicle_type_container'>
            <h4 className='select_vehicle__header'>
                Connect Your Vehicle <br />
                to QR
            </h4>
            <div className='select_vehicle_type_container__buttons'>
                <AddCarButton />
                <AddBikeButton />
            </div>
            <div className='img_container'>
                <img src='/select-vehicle-type.svg' />
            </div>
        </motion.div>
    )
}
