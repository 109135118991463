//Dependecies/Components
import { useState, useEffect } from 'react';
import Header from './components/header/Header';
import { motion } from 'framer-motion';

import useAuth from './useAuth'

//Images/Icons
import clockIcon from './assets/icons/clock-icon.svg'
import { useLocation, useNavigate } from 'react-router-dom'

//CSS
import './components/LoginOtp/LoginOtp.css'
import { verifyOtp, resendOtp, checkQrStatus } from './api/Auth';
import { getAllMyVehicles } from './api/VehiclesApi'

const LoginOtp = ({ setHeaderBack, setBackBtnText }) => {
  const [isAuth, login, logout] = useAuth(false);
  const [resend, setResend] = useState(true)
  const [error, setError] = useState('')
  setBackBtnText('OTP')
  setHeaderBack(true)
  const location = useLocation()
  let navigate = useNavigate()

  const [mobileNumber, setMobileNumber] = useState(location.state)

  let initialMinute = 0;
  let initialSeconds = 60;
  let length = 4

  initialMinute = initialMinute < 10 ? '0' + initialMinute : initialMinute;

  const [disable, setDisable] = useState(true)
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  // const [otpValues, setOtpValues] = useState(new Array(length).fill(''))
  const [otpValues, setOtpValues] = useState()

  let validate = []

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(0);
      setResend(false)
    }
  }, [seconds]);

  const showError = (error) => {
    setError(error)
    document.getElementsByClassName("otp-incorrect")[0].style.display = "block"
  }

  useEffect(() => {
    if (sessionStorage.getItem("user") && JSON.parse(sessionStorage.getItem("user")).authenticated) {
      navigate('/home')
    }
  }, [])

  useEffect(() => {
    if (!otpValues) return
    if (otpValues.length === 4) {
      setDisable(false)
    } else {
      setDisable(true)
    }
  }, [otpValues])

  const setAuth = (auth) => {
    let user = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")) : ''
    user.authenticated = auth
    sessionStorage.setItem('user', JSON.stringify(user))
  }

  const verifyOtpCall = async () => {
    await verifyOtp(mobileNumber, otpValues)
      .then(res => {
        if (res && res.token) {
          const userName = res.name;
          checkQrStatus(mobileNumber)
            .then(res => {
              if (res.data) {
                if (seconds === 0) return showError('OTP time expired, resend otp')
                if (!res.data.qrValid) return showError('invalid Qr')
                if (res.data.sameUser && res.data.vehicleAdd) {
                  setAuth(true)
                  navigate('/home')
                  return
                }
                if (!res.data.sameUser && res.data.vehicleAdd) return showError('Invalid Mobile number')
                if (!res.data.sameUser && !res.data.vehicleAdd) {
                  setAuth(false)
                  navigate('/select-vehicle-type')
                  return
                }
              } else {
                showError('Error occured')
              }
            })
            .catch(err => console.log(err))
          // getAllMyVehicles()
          //   .then(res => {
          //     if (res.data[1].vehicles.length > 0) {
          //       userName ? window.location.href = '/home' : navigate('/userinfo')
          //       sessionStorage.setItem("allVehicles", JSON.stringify(res.data[1].vehicles))
          //     } else {
          //       navigate('/select-vehicle-type')
          //     }
          //   })

        } else {
          showError('OTP is incorrect!')
        }

      })
  }

  const resendOtpCall = () => {
    resendOtp(mobileNumber)
      .then(res => res ? (setSeconds(60), setResend(true)) : alert("Something went wrong"))
  }

  const handleChange = (e) => {
    setOtpValues(e.target.value)
  }

  validate = otpValues
  // console.log(validate, otpValues);

  const validateForm = () => {
    (otpValues[0] !== '') && (otpValues[1] !== '') && (otpValues[2] !== '') && (otpValues[4] !== '') ? setDisable(false) : setDisable(true)
  }

  const pageVariants = {
    initial: {
      // opacity: 0,
      x: "100vw",
      // scale: 0.8
    },
    in: {
      // opacity: 1,
      x: 0,
      // scale: 1
    },
    out: {
      // opacity: 0,
      x: "-100vw",
      // scale: 1.2
    }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.3
  };

  return (
    <motion.div
      initial='initial'
      animate='in'
      exit='out'
      variants={pageVariants}
      transition={pageTransition}
      className='homescreen-container'>
      <div className='login-otp-container'>
        <div className="heading-otp">
          <h2 className="heading">Enter 4 Digit OTP</h2>
          <div className='sub-text d-flex align-items-center justify-content-between'>
            <p className="subheading">SMS Sent to {mobileNumber}</p>
            <p className="otp-incorrect"> {error} </p>
          </div>
        </div>
        <div className="otp-boxes d-flex align-items-center justify-content-between">
          <input type="number"
            maxLength="4"
            value={otpValues}
            onChange={e => handleChange(e)}
            className="otp-box" />
        </div>
        {/* <p>OTP entered : {otp.join('')}</p> */}
        <div className="prompts d-flex justify-content-between align-items-center">
          <button className="resend-prompt" disabled={resend} onClick={resendOtpCall}>Re-send OTP</button>
          <div className='timer-prompt d-flex align-items-center'>
            <div className="timer-image">
              <img src={clockIcon} alt="timer" />
            </div>
            <p>Time left {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</p>
          </div>
        </div>
        <button className="primary-submit-btn d-flex justify-content-center" disabled={disable} onClick={verifyOtpCall}><p>Next</p></button>
      </div>
    </motion.div>
  )
}

export default LoginOtp
