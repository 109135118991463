import React, { useState, useEffect, useRef } from 'react'
import Header from './components/header/Header'
import qrIcon from './assets/icons/qr-icon.svg'
import sendPlaneWhite from './assets/icons/send-plane-white.svg'
import './components/SecondHomeScreen/SecondHomeScreen.css'
import BottomModal from './components/BottomModal/BottomModal'
import { motion } from 'framer-motion'

import { postVehicleIssue } from './api/IssuesApi'
import { verifyVehicle } from './api/VehiclesApi'

const SecondHomeScreen = ({ setSecondHome }) => {

  const [displayModal, setDisplayModal] = useState(false);
  const [modalData, setModalData] = useState([])
  const [issuesList, setIssuesList] = useState([])
  const [disable, setDisable] = useState(true)
  const [vehicleNumber, setVehicleNumber] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [vehicleIssue, setVehicleIssue] = useState({
    issue_id: '',
    vehicle_id: ''
  })

  let userDetails = JSON.parse(sessionStorage.getItem("user"));
  const timeout = useRef()

  const handleShow = (e, data) => {
    e.preventDefault()
    postVehicleIssue(vehicleIssue)
      .then(res => {
        if (res) {
          setDisplayModal(true)
          setModalData(data)
        }
      })
  };

  useEffect(() => {
    let vehicleIssues = sessionStorage.getItem("vehicleIssues") ? JSON.parse(sessionStorage.getItem("vehicleIssues")) : []
    let issues = []
    vehicleIssues.forEach(issue => {
      issues.push(issue)
    })
    setIssuesList(issues)
    setSecondHome(true)
    sessionStorage.removeItem("verifyVehicle")
    sessionStorage.removeItem("vehicleIssueID")
  }, [])

  const vehicleNumberVerify = (e) => {
    clearTimeout(timeout.current)

    setVehicleNumber(e.target.value)

    timeout.current = setTimeout(() => {
      if (document.getElementById("vehicleNumber") !== '') {
        verifyVehicle(e.target.value)
          .then(res => {
            if (res && res.data) {
              setVehicleIssue({
                issue_id: sessionStorage.getItem("vehicleIssueID") ? sessionStorage.getItem("vehicleIssueID") : '',
                vehicle_id: res.data.sid,
                reporter_contact_number: mobileNumber
              })
              // setDisable(false)
              sessionStorage.setItem("verifyVehicle", res.data.sid)
              document.getElementById("vehicleNo-input").style.color = "#2E2F2F"
              document.getElementById("vehicleNo-input").innerText = 'Enter vehicle number'
              validateForm()
            } else {
              document.getElementById("vehicleNo-input").style.color = "red"
              document.getElementById("vehicleNo-input").innerText = "Enter Valid Vehicle Number"
              sessionStorage.removeItem("verifyVehicle")
              setVehicleIssue({
                issue_id: sessionStorage.getItem("vehicleIssueID") ? sessionStorage.getItem("vehicleIssueID") : '',
                vehicle_id: '',
                reporter_contact_number: mobileNumber
              })
              validateForm()
              // setDisable(true)
            }
          })
      }
    }, 1500);
  }

  const handleChange = (e, issueID) => {
    setVehicleIssue({
      issue_id: issueID,
      vehicle_id: sessionStorage.getItem("verifyVehicle") ? sessionStorage.getItem("verifyVehicle") : '',
      reporter_contact_number: mobileNumber
    })
    sessionStorage.setItem("vehicleIssueID", issueID);
  }

  const validateForm = () => {
    (sessionStorage.getItem("vehicleIssueID") && sessionStorage.getItem("verifyVehicle")) ? setDisable(false) : setDisable(true)
    // (vehicleIssue.issue_id && vehicleIssue.vehicle_id) ? setDisable(false) : setDisable(true)
  }

  // useEffect(() => {
  //   validateForm()
  // }, [vehicleIssue])

  const modalDetails = {
    secondHome: true,
    MHeading: 'Thank You for Sharing!',
    MSubheading: 'We have shared the issue with the owner, Thanks for caring 🙂',
  }

  // console.log(issuesList);

  const headerVariants = {
    initial: {
      opacity: 0,
      // y: "-100vw",
      // scale: 0.8
    },
    in: {
      opacity: 1,
      // y: 0,
      // scale: 1
    },
    out: {
      opacity: 0,
      // y: "-100vw",
      // scale: 1.2
    }
  };

  const secondPageVariants = {
    initial: {
      opacity: 0,
      y: "200vw",
      // scale: 0.8
    },
    in: {
      opacity: 1,
      y: 0,
      // scale: 1
    },
    out: {
      opacity: 0,
      y: "200vw",
      // scale: 1.2
    }
  };

  const pageTransition = {
    type: "tween",
    ease: "easeInOut",
    duration: 0.5
  };

  return (
    <div className='temp-color'>
      <motion.div
        initial='initial'
        animate='in'
        exit='out'
        variants={headerVariants}
        transition={pageTransition}
        className='trans-header'
      >
        {/* <Header secondHome={true} /> */}
      </motion.div>
      <motion.div
        initial='initial'
        animate='in'
        exit='out'
        variants={secondPageVariants}
        transition={pageTransition}
        // className='homescreen-container'
        className='temp-color'
      >
        <div className="second-home-wrapper">
          {/* <div className="owner-banner-container">
          </div> */}
          <form className="issue-form" onChange={validateForm}>
            <div className="issue-form-header d-flex flex-column">
              <h4>Contact vehicle owner</h4>
              <p>Please select a reason why do you want to contact vehicle owner</p>
            </div>
            <div className="issue-form-options">
              {
                issuesList.map((issue) => (
                  <div className="issue-item-container" key={issue.issueId}>
                    <label htmlFor={issue.issueId} className="radiobtn-label">
                      <input type="radio" name="issue" id={issue.issueId} onChange={(e) => handleChange(e, issue.issueId)} />
                      <span className="radio-custom"></span>
                      {issue.issueName}
                    </label>
                  </div>
                ))
              }
            </div>
          </form>
        </div>
        <BottomModal
          displayModal={displayModal}
          setDisplayModal={setDisplayModal}
          modalData={modalData}
        />
      </motion.div>
      <div className='issues-bottom'>
        <div className='mobile-number-container'>
          <input type="number" placeholder="phone number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
        </div>
        <footer className="d-flex justify-content-between">
          <div className="vehicle-number-container">
            <label htmlFor="" id="vehicleNo-input">Enter vehicle number</label>
            <input type="text" id="vehicleNumber" autoComplete="off" autoCapitalize="characters" placeholder="XX XXX XXXX" value={vehicleNumber} onChange={(e) => vehicleNumberVerify(e)} onKeyDown={validateForm} />
          </div>
          <button className='primary-submit-btn issue-btn d-flex justify-content-center align-items-center' disabled={disable} onClick={(e) => handleShow(e, modalDetails)}>
            <p>Send</p><span><img src={sendPlaneWhite} alt="" /></span>
          </button>
        </footer>
      </div>
    </div>
  )
}

export default SecondHomeScreen