/* eslint-disable */
import { useState, useEffect } from 'react'
import CarCard from './components/Car-CardComponent/CarCard'

import { getBookings } from './api/BookingsApi'

import './components/MyBookings/MyBookings.css'
// import SkeletonComponent from './components/SkeletonComponent/SkeletonComponent'
import Footer from './components/footer/Footer';
import { motion } from 'framer-motion';


const MyBookings = ({ setHeaderBack, setBackBtnText }) => {
  const [selectActive, setSelectActive] = useState(true)
  const [activeBookings, setActiveBookings] = useState([])
  const [completeBookings, setCompleteBookings] = useState([])
  const activeId = 1
  setHeaderBack(true)
  setBackBtnText('My Bookings')

  const handleSelect = () => {
    setSelectActive(!selectActive)
  };

  const customDateFormat = (date) => {

    // let date = "2021-11-05T00:00:00.000+00:00";
    date = new Date(date)

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    let day = (date.getDate() < 9 ? '0' + date.getDate() : date.getDate())
    let month = monthNames[date.getMonth()]
    let year = date.getFullYear()

    let finalDate = day + ' ' + month + ', ' + year

    return finalDate
  }

  useEffect(() => {
    getBookings()
      .then(res => {
        // res is an array of bookings here
        if (res) {
          res.forEach(booking => {
            if(booking.packageDetails.vehicleType==='TWO_WHEELER') return
            const bookingData = {
              id: booking.sid, // = order id
              CHeading: booking.packageDetails.name,
              CSubheading: '',
              CExtraButton: false,
              CRightPrice: false,
              CPrice: booking.price,
              paymentComplete: false,
              CRightExtraButton: false,
              CPriceCenter: false,
              onBoardStart: customDateFormat(booking.startDate),
              onBoardEnd: customDateFormat(booking.endDate),
              // internalStart: customDateFormat(booking.slots[0].slot),
              // internalEnd: customDateFormat(booking.slots[1].slot),
              internalStart: booking.packageDetails.vehicleType==='FOUR_WHEELER'? customDateFormat(booking.slots[0].slot) : '',
              internalEnd: booking.packageDetails.vehicleType==='FOUR_WHEELER'? customDateFormat(booking.slots[1].slot) : '',
              paymentComplete: booking.paymentStatus === "SUCCESS" ? true : false,
              bookingComplete: booking.bookingStatus === "COMPLETED" ? true : false,
              type: booking.packageDetails.vehicleType
            }
            booking.bookingStatus === "COMPLETED" ? carBookingCompleted.push(bookingData)
              : carBookingDetails.push(bookingData)
          })
          const sortedBookingDetails = carBookingDetails.sort((a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate))  // sortedBookingDetails & carBookingDetails both gets sorted
          const sortedBookingCompleted = carBookingCompleted.sort((a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate))
          
          setActiveBookings(carBookingDetails);
          setCompleteBookings(carBookingCompleted)
        }
      });
  }, [])

  const booking = true;
  // const skeletonCar = 'car'
  // const skeletonBike = 'bike'

  let carBookingDetails = [
    // {
    //   id: 1,
    //   CHeading: 'Daily Cleainng',
    //   CSubheading: '26 Exterior & 2 interior cleaning',
    //   CExtraButton: false,
    //   CRightPrice: false,
    //   CPrice: '450',
    //   paymentComplete: false,
    //   CRightExtraButton: false,
    //   CPriceCenter: false,
    //   onBoardStart: '15 Aug, 2021',
    //   onBoardEnd: '15 Sep, 2021',
    //   internalStart: 'Tue 2021-08-15',
    //   internalEnd: 'Tue, 2021-08-29',
    // },
    // {
    //   id: 2,
    //   CHeading: 'Daily Cleainng',
    //   CSubheading: '26 Exterior & 2 interior cleaning',
    //   CExtraButton: false,
    //   CRightPrice: false,
    //   CPrice: '350',
    //   cleanAmount: '250',
    //   paymentComplete: false,
    //   CRightExtraButton: false,
    //   CPriceCenter: false,
    //   onBoardStart: '15 Aug, 2021',
    //   onBoardEnd: '15 Sep, 2021',
    //   internalStart: 'Tue 2021-08-15',
    //   internalEnd: 'Tue, 2021-08-29',
    // },
    // {
    //   id: 3,
    //   CHeading: 'Daily Cleainng',
    //   CSubheading: '26 Exterior & 2 interior cleaning',
    //   CExtraButton: false,
    //   CRightPrice: false,
    //   CPrice: '250',
    //   CRightExtraButton: false,
    //   paymentComplete: false,
    //   CPriceCenter: false,
    //   onBoardStart: '15 Aug, 2021',
    //   onBoardEnd: '15 Sep, 2021',
    //   internalStart: 'Tue 2021-08-15',
    //   internalEnd: 'Tue, 2021-08-29',
    // },
  ]

  let carBookingCompleted = [
    // {
    //   id: 1,
    //   CHeading: 'Daily Cleainng',
    //   CSubheading: '26 Exterior & 2 interior cleaning',
    //   CExtraButton: false,
    //   CRightPrice: false,
    //   CPrice: '450',
    //   CRightExtraButton: false,
    //   paymentComplete: true,
    //   CPriceCenter: false,
    //   onBoardStart: '15 Aug, 2021',
    //   onBoardEnd: '15 Sep, 2021',
    //   internalStart: 'Tue 2021-08-15',
    //   internalEnd: 'Tue, 2021-08-29',
    // },
    // {
    //   id: 2,
    //   CHeading: 'Daily Cleainng',
    //   CSubheading: '26 Exterior & 2 interior cleaning',
    //   CExtraButton: false,
    //   CRightPrice: false,
    //   CPrice: '250',
    //   paymentComplete: true,
    //   CRightExtraButton: false,
    //   CPriceCenter: false,
    //   onBoardStart: '15 Aug, 2021',
    //   onBoardEnd: '15 Sep, 2021',
    //   internalStart: 'Tue 2021-08-15',
    //   internalEnd: 'Tue, 2021-08-29',
    // },
    // {
    //   id: 3,
    //   CHeading: 'Daily Cleainng',
    //   CSubheading: '26 Exterior & 2 interior cleaning',
    //   CExtraButton: false,
    //   CRightPrice: false,
    //   CPrice: '250',
    //   CRightExtraButton: false,
    //   paymentComplete: false,
    //   CPriceCenter: false,
    //   onBoardStart: '15 Aug, 2021',
    //   onBoardEnd: '15 Sep, 2021',
    //   internalStart: 'Tue 2021-08-15',
    //   internalEnd: 'Tue, 2021-08-29',
    // },
  ]

  const pageVariants = {
    initial: {
      // opacity: 0,
      x: "100vw",
      // scale: 0.8
    },
    in: {
      // opacity: 1,
      x: 0,
      // scale: 1
    },
    out: {
      // opacity: 0,
      x: "-100vw",
      // scale: 1.2
    }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.3
  };

  return (
    <>
      <motion.div
        initial='initial'
        animate='in'
        exit='out'
        variants={pageVariants}
        transition={pageTransition}
        className='homescreen-container'>
        <div className='bookings-container'>
          <div className="tabs-section d-flex">
            <div className={`active-tab d-flex justify-content-center align-items-end ${selectActive ? 'selected' : ''}`} onClick={() => handleSelect()}>
              <p>Active Bookings</p>
            </div>
            <div className={`completed-tab d-flex justify-content-center align-items-end ${selectActive ? '' : 'selected'}`} onClick={() => handleSelect()}>
              <p>Completed</p>
            </div>
          </div>
          {
            selectActive ? (
              <div className="booking-wrapper d-flex flex-column">
                {/* <SkeletonComponent type={skeletonCar} />
              <SkeletonComponent type={skeletonBike} /> */}
                {
                  activeBookings.map((car) => (
                    <CarCard condBooking={booking} car={car} selectActive={selectActive} key={car.id} />
                  ))
                }
              </div>
            ) : (
              <div className="booking-wrapper d-flex flex-column">
                {
                  completeBookings.map((car) => (
                    <CarCard condBooking={booking} car={car} selectActive={selectActive} key={car.id} />
                  ))
                }
              </div>
            )
          }
        </div>
      </motion.div>
      <Footer activeId={activeId} />
    </>
  )
}

export default MyBookings
