import CarCard from './components/Car-CardComponent/CarCard';
import BottomModal from './components/BottomModal/BottomModal'
import { useState, useEffect, useCallback } from 'react'
import { motion } from 'framer-motion';

import { getPackages } from './api/UserApi'

//CSS
import './components/Sanitisation/Sanitisation.css'


const Sanitisation = ({ setHeaderBack, setBackBtnText }) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [modalData, setModalData] = useState([])
  const [packagesDetails, setPackagesDetails] = useState({});
  const [bikePackagesDetails, setBikePackagesDetails] = useState([]);
  const [carCardInfo, setCarCardInfo] = useState([]);
  const [bikeCardInfo, setBikeCardInfo] = useState([])
  setHeaderBack(true)
  setBackBtnText('Daily Sanitisation & Cleaning')

  const packageApi = useCallback(() => {
    // Get Packages
    getPackages()
      .then(res => {
        if (res) {
          const packages = {
            packageId: res.carPackage.packages.sid,
            MHeading: res.carPackage.packages.name,
            packageLabel: res.carPackage.packageLabel,
            packageWashinfo: res.carPackage.packages.washInfos.join(' & '),
            MButtonText: 'Next',
            MBottomPrice: true,
            MPrice: res.carPackage.packages.price + '/month',
            price: JSON.stringify(res.carPackage.packages.price),
            packageList: true,
            packageListHeading: 'Package contains following things :-',
            packageListDetials: res.carPackage.packages.infos,
            packageVehicleType: 'FOUR_WHEELER'
          }
          const bikePackages = res.bikePackage.packages.map(bikePackage => {
            return {
              packageId: bikePackage.packages.sid,
              MHeading: bikePackage.packages.name,
              packageLabel: bikePackage.packageLabel,
              packageWashinfo: bikePackage.packages.washInfos.join(' & '),
              MButtonText: 'Next',
              MBottomPrice: true,
              MPrice: bikePackage.packages.price + '/month',
              price: JSON.stringify(bikePackage.packages.price),
              packageList: true,
              packageListHeading: 'Package contains following things :-',
              packageListDetials: bikePackage.packages.infos,
              packageVehicleType: 'TWO_WHEELER'
            }
          })
          const cardDetails = [{
            id: res.carPackage.packages.sid,
            CHeading: res.carPackage.packages.name,
            CSubheading: res.carPackage.packages.washInfos.join(' & '),
            CExtraButton: true,
            CExtraButtonText: 'View Details',
            CRightPrice: true,
            CRightExtraButton: false,
            CPriceCenter: false,
            CPrice: res.carPackage.packages.price,
            ButtonLink: '/subscription',
            type: 'FOUR_WHEELER'
          }]
          const bikeCardDetails = res.bikePackage.packages.map(bikepackage => {
            return {
              id: bikepackage.packages.sid,
              CHeading: bikepackage.packages.name,
              CSubheading: bikepackage.packages.washInfos.join(' & '),
              CExtraButton: true,
              CExtraButtonText: 'View Details',
              CRightPrice: true,
              CRightExtraButton: false,
              CPriceCenter: false,
              CPrice: bikepackage.packages.price,
              ButtonLink: '/subscription',
              type: 'TWO_WHEELER'
            }
          })
          setPackagesDetails(packages)
          setBikePackagesDetails(bikePackages)
          setCarCardInfo(cardDetails)
          setBikeCardInfo(bikeCardDetails)
          // console.log("PACKAGESSS", packages);
          let carPackages = [{packages}]
          sessionStorage.setItem('carPackages', JSON.stringify([carPackages[0].packages]))
          sessionStorage.setItem('bikePackages', JSON.stringify(bikePackages))
        }
      })
  }, [])

  useEffect(() => {
    packageApi();
  }, [packageApi])


  const pageVariants = {
    initial: {
      // opacity: 0,
      x: "100vw",
      // scale: 0.8
    },
    in: {
      // opacity: 1,
      x: 0,
      // scale: 1
    },
    out: {
      // opacity: 0,
      x: "-100vw",
      // scale: 1.2
    }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.3
  };

  return (
    <motion.div
      initial='initial'
      animate='in'
      exit='out'
      variants={pageVariants}
      transition={pageTransition}
      className='homescreen-container'
    >
      <div className='sanitisation-container'>
        <div className="sanitisation-content d-flex flex-column">
          <h2 className="sanitisation-header">
            {packagesDetails.packageLabel}
          </h2>
          {/* <p className="sanitisation-subheading">
            Select a package as per your requirement
          </p> */}
          {
            carCardInfo.map((car) => (
              <CarCard
                displayModal={displayModal}
                setDisplayModal={setDisplayModal}
                setModalData={setModalData}
                packageDetails={packagesDetails}
                car={car}
                sanitisationPage={true}
                key={car.id}
              />
            ))
          }
          <h2 className="sanitisation-header mt-4">
            {bikePackagesDetails.length > 0 && bikePackagesDetails[0].packageLabel}
          </h2>
          {
            bikeCardInfo.map((car, idx) => (
              <CarCard
                displayModal={displayModal}
                setDisplayModal={setDisplayModal}
                setModalData={setModalData}
                packageDetails={bikePackagesDetails[idx]}
                car={car}
                sanitisationPage={true}
                key={car.id}
              />
            ))
          }
        </div>
        <BottomModal
          displayModal={displayModal}
          setDisplayModal={setDisplayModal}
          modalData={modalData}
        />
      </div>
    </motion.div>
  )
}

export default Sanitisation
