import React from 'react'
import { useState, useEffect } from 'react';
import ruppeeIcon from '../../assets/icons/currency-inr-icon.svg'
import CarCard from '../Car-CardComponent/CarCard';
import CarBox from '../CarBoxComponent/CarBox'
import './BottomModal.css'
import Sheet from 'react-modal-sheet';
import checkGreenIcon from '../../assets/icons/check-green-icon.svg'
import closeIcon from '../../assets/icons/close.svg'
import { Link, useNavigate } from 'react-router-dom';
import { postParkingIssue } from '../../api/IssuesApi'
import CallNowIcon from '../../assets/icons/call-now.svg'
import WhatsappIcon from '../../assets/icons/whatsapp-chat.svg'
import IssueCarBox from '../IssueCarBox/issueCarBox';

const BottomModal = ({
  displayModal,
  setDisplayModal,
  modalData,
  setSelectedDate,
  vehicleComp,
  MParkingIssues,
  setSelectCar,
  setVehicleName,
  vehicleList,
  setbookingType,
  vehicleDetails,
  setSearchVehicle,
  paymentSuccess
}) => {
  const [bookingConfirmed, setbookingConfirmed] = useState(false)
  const [issue, setIssue] = useState(false)
  // const [selectedIssues, setselectedIssues] = useState({
  //   issue_id: [],
  //   vehicle_id: ''
  // })
  // const [selectedIssues, setselectedIssues] = useState([])
  const [btnDisable, setBtnDisable] = useState(true)
  const [vehicleSelected, setVehicleSelected] = useState({ vehicleId: '', vehicleName: '', vehicleNumber: '' })
  // const [secondHome, setSecondHome] = useState(false)
  // const [disable, setDisable] = useState(true)
  const [selectCustomCar, setSelectCustomCar] = useState([])
  let buttonText1 = modalData.MButtonText1
  let buttonText2 = modalData.MButtonText2
  let navigate = useNavigate()

  let confirmText
  if (bookingConfirmed || modalData.secondHome) {
    confirmText = 'Done'
  } else if (issue) {
    confirmText = 'Back'
  } else {
    confirmText = ''
  }

  const vehicleSelect = (e) => {
    setSearchVehicle((e.target.value).toLowerCase())
    // To add custom model if not in the list
    setSelectCustomCar(e.target.value)
  }
   
  const postIssue = () => {
    if (sessionStorage.getItem('selectedPI')) {
      postParkingIssue(vehicleSelected.vehicleId)
        .then(res => {
          if (res) {
            // console.log(res)
            setIssue(true)
            sessionStorage.removeItem('selectedPI')
            setVehicleSelected({ vehicleId: '' })
          }
        })
    } else {
      alert("Select at least one issue to continue")
    }

  }

  let issueArray = [];
  const issueSelection = (e, data) => {
    let newIssuesArray = []
    if (e.target.checked) {
      issueArray.push(data.id)
      sessionStorage.setItem("selectedPI", JSON.stringify(issueArray))
      validateForm()
    } else {
      issueArray.forEach(issue => {
        if (issue !== data.id) {
          newIssuesArray.push(issue)
        }
      })
      issueArray = newIssuesArray;
      sessionStorage.setItem("selectedPI", JSON.stringify(issueArray))
      validateForm()
    }

    // setselectedIssues({
    //   issue_id: issueArray,
    //   vehicle_id: vehicleSelected ? vehicleSelected.vehicleId : ''
    // })
    // console.log("ISSUESS", issueArray);
  }

  useEffect(() => {
    setbookingConfirmed(paymentSuccess)
    // console.log("asda", paymentSuccess);
  }, [paymentSuccess])

  useEffect(() => {
    validateForm()
  }, [vehicleSelected.vehicleName])

  const handleClose = () => {
    setDisplayModal(false)
    setbookingConfirmed(false)
    setIssue(false)
    setSelectCustomCar([])
    // setSearchVehicle('')
  };

  const HandleDate = () => {
    let setDateSlot = document.querySelector('input[name="date"]:checked').id
    let setDateLabel = document.querySelector('input[name="date"]:checked').value
    setSelectedDate({ dateSlot: setDateSlot, dateLabel: setDateLabel })
    setDisplayModal(false)
  }

  const bookingConfirm = (bookingtype) => {
    setbookingType(bookingtype);
    if (bookingtype === "PAY_LATER") {
      setDisplayModal(true)
      setbookingConfirmed(true)
    }
  }

  const handleRedirect = (page) => {
    navigate(page)
  }

  const handleCar = (customCar) => {
    let tempCar
    if (customCar) {
      tempCar = selectCustomCar
    } else {
      tempCar = document.querySelector('input[name="vehicle"]:checked').value
    }
    setSelectCar(tempCar)
    setVehicleName(tempCar)
    handleClose();
  }

  let AmountDetails =  modalData.AmountDetails;
  let ChangeDate = modalData.dateChangeDetails
  let carCardDetails = modalData.ChangePackage

  const validateForm = () => {
    (vehicleSelected.vehicleName !== "") && (sessionStorage.getItem('selectedPI') ? JSON.parse(sessionStorage.getItem('selectedPI')).length > 0 : false) ? setBtnDisable(false) : setBtnDisable(true)
  }


  return (
    <Sheet
      isOpen={displayModal}
      onClose={() => handleClose()}
    >
      <Sheet.Container>
        <Sheet.Header>
          <div className="drag-bar d-flex justify-content-center align-content-center">
            {/* <div className="drag-dark-bar"></div> */}
          </div>
        </Sheet.Header>
          <div onClick={handleClose} className="modal-close-icon"> <img src={closeIcon}/> </div>
        <Sheet.Content>
          <div className="modal-content-box">
            <div className="modal-content-header">
              {
                bookingConfirmed || issue || modalData.secondHome ? (
                  <div className='check-green-icon'>
                    <img src={checkGreenIcon} alt="" />
                  </div>
                ) : ('')
              }
              <h3 className='modal-heading'>
                {
                  bookingConfirmed ? 'Booking Confirmed' : issue ? 'Thank You for Sharing!' : modalData.MHeading
                }
              </h3>
              <p className={`modal-subheading ${issue ? 'medium-subheading' : ''} ${modalData.secondHome ? 'small-subheading' : ''}`}>
                {
                  bookingConfirmed ? 'Please check the my bookings section for your booking updates. Thank You! 🙂' : issue ? 'We have shared the issue with the Society Association, they will update you on the issue.' : modalData.MSubheading
                }
              </p>
            </div>
            {
              issue ? ('') :
                (
                  modalData.MCheckBox ? (
                    <div className="checkbox-container d-flex flex-column">
                      <h4><b>Select Vehicle</b></h4>
                      <div className="vehicle-container-issue d-inline-flex">
                        <IssueCarBox carData={vehicleDetails} setVehicleSelected={setVehicleSelected} />
                      </div>
                      {
                        MParkingIssues.map((data) => (
                          <label htmlFor={`id-1-${data.id}`} className="checkbox-label checkbox-item d-flex align-items-center" key={data.id}>
                            <input type="checkbox" name="1" id={`id-1-${data.id}`} onChange={(e) => { issueSelection(e, data) }} />
                            <span className="custom-checkmark"></span>
                            {data.label}
                          </label>
                        ))
                      }
                    </div>
                  ) : ''
                )
            }
            {
              modalData.packageList ? (
                <div className="list-container">
                  <h6 className="list-heading">
                    {modalData.packageListHeading}
                  </h6>
                  <ul className='package-details-list'>
                    {modalData.packageListDetials.map((element, index) => (
                      <li key={index}><p>{element}</p></li>
                    ))}
                  </ul>
                </div>
              ) : ('')
            }
            {
              modalData.MChangePackage ? (
                <div className="car-card-wrapper d-flex flex-column justify-content-center">
                  {carCardDetails.map((car) => (
                    <CarCard car={car} key={car.id} MChangePackage={modalData.MChangePackage} />
                  ))}
                </div>
              ) : ('')
            }
            {bookingConfirmed ? ('') :
              (
                modalData.Info || modalData.ProceedPay ? (
                  <>
                    {AmountDetails.map((item) => (
                      <div className="amount-item d-flex justify-content-between" key={item.id}>
                        <p className="car-name">{item.carName}</p>
                        <div className="car-price d-flex">
                          <div className="price-icon-m small-icon">
                            <img src={ruppeeIcon} alt="" />
                          </div>
                          <p>{item.carPrice}</p>
                        </div>
                      </div>
                    ))
                    }
                    <div className="total-amount-container d-flex justify-content-between align-items-center">
                      <p className="totalText">Total</p>
                      <div className="info-amount d-flex">
                        <div className="price-icon-m medium-icon">
                          <img src={ruppeeIcon} alt="" />
                        </div>
                        <h6>{modalData.totalAmount}</h6>
                      </div>
                    </div>
                  </>
                ) :
                  ('')
              )}
            {
              modalData.dateChange ? (
                <div className="dateChange-container">
                  {
                    ChangeDate.map((date, index) => (
                      <div className="date-container" key={index}>
                        <label htmlFor={date.slot} className="radiobtn-label">
                          <input type="radio" name='date' id={date.slot} value={date.label} />
                          <span className="radio-custom"></span>{date.label}
                        </label>
                      </div>
                    ))
                  }
                </div>
              ) : ('')
            }
            {
              modalData.vehicleListCond ? (
                <>
                  <div className="modal-input-field d-flex flex-column align-items-center justify-content-between">
                    <input type='text' placeholder='Search Vehicle' className='custom-car-input' id='vehicle-name' autoComplete="off" value={selectCustomCar} onChange={(e) => { vehicleSelect(e) }} />
                    <button className='modal-add-custom' onClick={() => handleCar(true)}>Add Custom</button>
                  </div>
                  <div className="car-select-container">
                    {
                      vehicleList.map((vehicle, index) => (
                        <div className="car-container" key={index}>
                          <label htmlFor={vehicle}>
                            <input type="radio" name="vehicle" value={vehicle} id={vehicle} onClick={() => { handleCar(false); setSelectCar(vehicle) }} />
                            {vehicle}
                          </label>
                        </div>
                      ))
                    }
                  </div>
                </>
              ) : ('')
            }
            {
              modalData.vehicleListCond ? ('') : (

                <div className={`bottom-btns d-flex justify-content-between ${modalData.Info ? 'height-0' : ''}`}>
                  {
                    modalData.packageList ? (
                      <div className="price-box">
                        <p>Price</p>
                        <div className="amount d-flex flex-row">
                          <div className="price-icon-m">
                            <img src={ruppeeIcon} alt="" />
                          </div>
                          <h6>{modalData.MPrice}</h6>
                        </div>
                      </div>
                    ) : ('')
                  }
                  {
                    modalData.packageList ? (
                      <Link className={`primary-submit-btn d-flex justify-content-center align-items-center`} to={`/subscription`} onClick={() =>{sessionStorage.setItem('packageSelected', JSON.stringify(modalData));handleClose()}}>
                        {modalData.MButtonText}
                      </Link>
                    ) :
                      (
                        modalData.Info || modalData.vehicleListCond ? ('') : (
                          modalData.ProceedPay || modalData.help || vehicleComp ? (
                            bookingConfirmed ? (
                              <button className={`primary-submit-btn d-flex justify-content-center align-items-center`}
                                onClick={(() => handleRedirect('/mybookings'))
                                }
                              >
                                {
                                  bookingConfirmed ? confirmText : modalData.MButtonText
                                }
                              </button>
                            ) : (
                              <>
                                {/* {
                                    vehicleComp || modalData.help ? <button className='secondary-submit-btn pay-now-btn' onClick={vehicleComp ? () => handleClose() : modalData.help ? () => { window.location.href = modalData.mailTo } : () => bookingConfirm("PAY_NOW")}>{modalData.help || vehicleComp ? buttonText1 : 'Pay Now'}</button> : null
                                  } */}                               
                                <button className='secondary-submit-btn pay-now-btn' onClick={vehicleComp ? () => handleClose() : modalData.help ? () => { window.location.href = modalData.callLink } : () => bookingConfirm("PAY_NOW")}>
                                  {modalData.help && <img src={CallNowIcon} className='btn-icon call-now-icon' />}
                                  {modalData.help || vehicleComp ? buttonText1 : 'Pay Now'}
                                </button>
                                <button className='primary-submit-btn pay-later-btn' id={modalData.help ? 'chat-btn' : ''} value={vehicleComp ? modalData.carDeleteId : ''} onClick={vehicleComp ? null : modalData.help ? () => { window.location.href = modalData.whatsAppChannel } : () => bookingConfirm("PAY_LATER")}>
                                  {modalData.help && <img src={WhatsappIcon} className='btn-icon whatsapp-chat-icon' />}
                                  {modalData.help || vehicleComp ? buttonText2 : 'Pay Later'}
                                </button>
                              </>
                            )
                          ) : (
                            <button className={`primary-submit-btn d-flex justify-content-center align-items-center hello-there`}
                              onClick={
                                modalData.MCheckBox ?
                                  (issue ? (() => handleClose()) : (() => { postIssue() }))
                                  : modalData.dateChange ? (() => HandleDate())
                                    : modalData.secondHome ? (() => handleRedirect('/'))
                                      : modalData.requiredInfo ? (() => handleRedirect('/userinfo'))
                                        : modalData.callLink ? () => { window.location.href = modalData.callLink } : (() => handleClose())
                              }
                              disabled={
                                issue || modalData.dateChange || modalData.secondHome || modalData.requiredInfo || modalData.callLink || modalData.MChangePackage ?
                                  '' :
                                  btnDisable}
                            >
                              {
                                issue || modalData.secondHome ? confirmText : modalData.MButtonText
                              }
                            </button>
                          )
                        )
                      )
                  }
                </div>
              )
            }
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <div
        className={`Overlay ${displayModal ? "Show" : ""}`}
        onClick={() => handleClose()}
      />
    </Sheet>
  )
}

export default BottomModal
