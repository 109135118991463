import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function AddBikeButton() {
    
    let navigate = useNavigate()

    return (
        <button className='select_vehicle_type_container__button' onClick={() => navigate('/addbike')} >
            <img src='/add-bike.svg' />
            <div className='d-flex align-items-center button_right'>
                <p>Add Bike</p>
                <img src='/arrow-right.svg' />
            </div>
        </button>
    )
}
