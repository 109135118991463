/* eslint-disable */
import { useState, useEffect, useCallback } from 'react'
import { getBanner } from '../../api/UserApi'

import bannerImage from '../../assets/icons/homescreen-banner.svg'
import phoneIcon from '../../assets/icons/phone-icon.svg'
import emailIcon from '../../assets/icons/email-icon.svg'
import './Banner.css'

const Banner = ({ profile }) => {

  const [bannerImageSrc, setBannerImageSrc] = useState('')
  let userDetails = JSON.parse(sessionStorage.getItem("user"))
  // let userDetails = {
  //   name: 'John Doe',
  //   email: 'john@gmail.com'
  // }
  const bannerApi = useCallback(() => {

    getBanner()
      .then(res => {
        if (res) {
          setBannerImageSrc(res.data[0].url)
          console.log(res.data[0].url);
        }
      })

  }, [])

  useEffect(() => {
    bannerApi()
  }, [bannerApi])

  return (
    <>
      {
        profile ? (
          <div className="profile-banner d-flex flex-column">
            <h1 className="profile-owner">Hi, {userDetails.name}!</h1>
            <div className="profile-contact-details d-flex flex-column">
              <div className="profile-phone d-flex justify-content-start align-items-center"><span><img src={phoneIcon} alt="" /></span><p>{userDetails.contact}</p></div>
              <div className="profile-email d-flex justify-content-start align-items-center">
                {
                  userDetails.email ? (
                    <>
                      <span><img src={emailIcon} alt="" /></span>
                      <p>{userDetails.email}</p></>
                  ) : (<></>)
                }
              </div>
            </div>
          </div>
        ) : (
          <div className="banner d-flex justify-content-between">
            <div className="banner-content d-flex flex-column">
              <h1 className="banner-heading">COVID Free Vehicle</h1>
              <p className="banner-subheading">Subscribe to Daily Sanitisation & Cleaning</p>
            </div>
            <div className="banner-image">
              <img src={bannerImage} alt="Banner" />
            </div>
          </div>
        )
      }
    </>
  )
}

export default Banner
