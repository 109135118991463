import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

import userOutlineIcon from './assets/icons/user-outline-icon.svg'
import Header from './components/header/Header'
import Banner from './components/Banner/Banner'
import './components/MyProfile/MyProfile.css'
import carBlackIcon from './assets/icons/car-black-icon.svg'
import infoBigIcon from './assets/icons/info-big-icon.svg'
import supportIcon from './assets/icons/support-black-icon.svg'
import docsIcon from './assets/icons/docs-black-icon.svg'
import verifiedIcon from './assets/icons/verified-black-icon.svg'
import logoutIcon from './assets/icons/logout-black-icon.svg'
import rightArrow from './assets/icons/right-black-arrow.svg'
import Footer from './components/footer/Footer'
import BottomModal from './components/BottomModal/BottomModal'
import { motion } from 'framer-motion'
import { logoutUser } from './api/Auth'
import CallIcon from './assets/icons/call.svg'
import EmailIcon from './assets/icons/email.svg'

const MyProfile = ({ setHeaderBack, setBackBtnText }) => {
  setHeaderBack(true)
  setBackBtnText('')
  const [displayModal, setDisplayModal] = useState(false);
  const [modalData, setModalData] = useState([])
  // const [config, setConfig] = useState({
  //   serviiMail: '',
  //   privacyPolicyLink: '',
  //   tncLink: '',
  // })

  const activeId = 3

  const handleShow = (Data) => {
    // MData = Data;
    setDisplayModal(true)
    setModalData(Data)
  };

  useEffect(() => {

  }, [])

  const logout = () => {
    logoutUser()
      .then(res => console.log("Logged Out"))
  }

  const helpDetails = {
    help: true,
    MHeading: 'Help & Support',
    MSubheading: 'We are here to help you, Feel free to call us',
    MButtonText1: 'Call Now',
    MButtonText2: 'Chat',
    callLink: 'tel:+918123310356',
    mailTo: 'https://mail.google.com/mail/?view=cm&fs=1&to=support@servii.in',
    whatsAppChannel: 'https://wa.aisensy.com/0MBpzD'
  }

  const menuList = [
    {
      id: 1,
      menuName: 'My Vehicles',
      menuIcon: carBlackIcon,
      menuLink: '/myvehicles',
      MData: null,
    },
    {
      id: 2,
      menuName: 'User Info',
      menuIcon: userOutlineIcon,
      menuLink: '/userinfo',
      MData: null,
    },
    {
      id: 3,
      menuName: 'Raised Issues',
      menuIcon: infoBigIcon,
      menuLink: '/raisedissues',
      MData: null,
    },
    {
      id: 4,
      menuName: 'Help & Support',
      menuIcon: supportIcon,
      menuLink: '/myprofile',
      MData: helpDetails,
    },
    {
      id: 5,
      menuName: 'Terms & Conditions',
      menuIcon: docsIcon,
      menuLink: `${process.env.REACT_APP_TNC}`,
      // menuLink: `/`,
      MData: null,
      hyperLinks: true
    },
    {
      id: 6,
      menuName: 'Privacy Policy',
      menuIcon: verifiedIcon,
      menuLink: `${process.env.REACT_APP_PP}`,
      // menuLink: `/`,
      MData: null,
      hyperLinks: true
    },
    {
      id: 7,
      menuName: 'Log Out',
      menuIcon: logoutIcon,
      MData: null,
      logout: true,
      menuLink: `/`,
    },
  ]

  const profilepageVariants = {
    initial: {
      // opacity: 0,
      x: "100vw",
      // scale: 0.8
    },
    in: {
      // opacity: 1,
      x: 0,
      // scale: 1
    },
    out: {
      // opacity: 0,
      x: "-100vw",
      // scale: 1.2
    }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.3
  };

  return (
    <>
      <motion.div
        initial='initial'
        animate='in'
        exit='out'
        variants={profilepageVariants}
        transition={pageTransition}
        className='homescreen-container'>
        <div className="profile-wrapper">
          <Banner profile={true} />

          <div className="menu-container d-flex flex-column">
            {/* <div className='user-detail'>
              <h4>Hi, Vishal Adarsh!</h4>
              <div className='d-flex align-items-center user-contact' >
                <img src={CallIcon} className='icon' />
                <p> +91 1234567890 </p>
              </div>
              <div className='d-flex align-items-center user-email' >
                <img src={EmailIcon} className='icon' />
                <p> vishaladc@gamail.com </p>
              </div>
            </div> */}
            {
              menuList.map((menu) => (
                menu.hyperLinks ? (
                  <div className="menu-item d-flex justify-content-between" key={menu.id} onClick={() => { window.location.href = menu.menuLink }}>
                    <div className="item-content d-flex">
                      <span><img src={menu.menuIcon} alt="" /></span>
                      <p>{menu.menuName}</p>
                    </div>
                    <span className="right-arrow">
                      <img src={rightArrow} alt="" />
                    </span>
                  </div>
                ) : (
                  <Link to={menu.menuLink} className="menu-item d-flex justify-content-between" key={menu.id} onClick={() => menu.logout ? logout() : handleShow(menu.MData)}>
                    <div className="item-content d-flex">
                      <span><img src={menu.menuIcon} alt="" /></span>
                      <p>{menu.menuName}</p>
                    </div>
                    <span className="right-arrow">
                      <img src={rightArrow} alt="" />
                    </span>
                  </Link>

                )
              ))
            }
          </div>
        </div>
        <BottomModal
          displayModal={displayModal}
          setDisplayModal={setDisplayModal}
          modalData={modalData}
        />
      </motion.div>
      <Footer activeId={activeId} />
    </>
  )
}

export default MyProfile
