import axios from 'axios'

// let userToken = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).token : ''

// const headers = {
//   "Access-Control-Allow-origin": "*",
//   'Content-Type': 'application/json',
//   "Authorization": `Bearer ${userToken}`
// }

// Get Booking dates (onboarding Dates)
export const getBookingDates = async () => {
  let userToken = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).token : ''
  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${userToken}`
  }

  let bookingDates;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/booking/details`, { headers })
    .then(res => bookingDates = res.data.data)
    .catch(err => console.log(err))

  return bookingDates;
}

// Get Internal Dates
export const getInternaldates = async (date) => {
  let userToken = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).token : ''
  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${userToken}`
  }

  // date example: "2021-02-01"

  let dateResponse;
  const onboardDate = JSON.stringify({
    fromDate: date
  })

  await axios.post(`${process.env.REACT_APP_BASE_URL}/booking/freeDates`, onboardDate, { headers })
    .then(res => dateResponse = res.data.data)
    .catch(err => console.log(err))

  return dateResponse;
}

// Save Bookings
export const saveBooking = async (bookingDetails) => {
  let userToken = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).token : ''
  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${userToken}`
  }

  let bookingResponse;

  await axios.post(`${process.env.REACT_APP_BASE_URL}/booking/save`, JSON.stringify(bookingDetails), { headers })
    .then(res => { bookingResponse = res.data })
    .catch(err => console.log(err))

  return bookingResponse;
}

// Get My Bookings
export const getBookings = async () => {
  let userToken = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).token : ''
  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${userToken}`
  }
  // let resBookings = [];
  let bookings = [];

  await axios.get(`${process.env.REACT_APP_BASE_URL}/booking/myList`, { headers })
    .then(res => res ? bookings = res.data.data : console.log("Undefined Response"))
    .catch(err => console.log(err))

  return bookings

}

// Pay Later
export const payLater = async (booking_id) => {
  let userToken = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).token : ''
  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/x-www-form-urlencoded',
    "Authorization": `Bearer ${userToken}`
  }

  const params = new URLSearchParams();
  params.append('paymentGateway', "RAZORPAY");

  //booking_id == sid for bookings
  await axios.post(`${process.env.REACT_APP_BASE_URL}/booking/${booking_id}/payLater`, params, { headers })
    .then(res => {
      if (res) {
        const data = res.data.data
        const orderDetails = {
          amount: data.amount,
          currency: data.currency,
          order_id: data.order_id,
        }
        razorPay(orderDetails)
          .then(res => console.log('TRXN SUCCESS', res))
      }
    })
    .catch(err => console.log(err))

}

// RazorPay API
export const razorPay = async (orderDetails) => {

  const userInfo = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {}

  var options = {
    key: `${process.env.REACT_APP_RZP_KEY}`,
    amount: orderDetails.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: orderDetails.currency,
    name: userInfo.name,
    description: "Payment To Servii",
    image: "servii-logo-full.png",
    order_id: orderDetails.order_id,
    handler: function (response) {
      const rzpResponse = {
        orderId: response.razorpay_order_id,
        paymentId: response.razorpay_payment_id,
        signature: response.razorpay_signature
      }
      saveTransaction(rzpResponse)
        .then(res => {
          if (res) {
            // window.location.href = "/mybookings";
            // sessionStorage.removeItem("packageSelected");
          }
        })
    },
    prefill: {
      name: userInfo.name,
      email: userInfo.email,
      contact: userInfo.contact
    },
    notes: {
      address: "Razorpay Corporate Office"
    },
    theme: {
      color: "#3399cc"
    }
  };

  var rzp1 = new window.Razorpay(options);

  rzp1.on('payment.failed', function (response) {
    alert(`${response.error.code} : ${response.error.description} Please Retry`)
  });
  rzp1.open();

}

// Razorpay Transaction Save
export const saveTransaction = async (trxnDetails) => {
  let userToken = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).token : ''
  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${userToken}`
  }

  let trxnSaved;

  await axios.post(`${process.env.REACT_APP_BASE_URL}/v2/transaction/save`, trxnDetails, { headers })
    .then(res => { trxnSaved = true })
    .catch(err => console.log(err))

  return trxnSaved
}

