
import { useEffect, useCallback, useState } from 'react'
import { getVehicleIssues } from './api/IssuesApi'

import { useLocation, useNavigate } from 'react-router-dom'

//Components
import Card from "./components/cardComponents/Card";
import Header from './components/header/Header';
import { motion } from "framer-motion";

//Icons/Images
import loginBanner from './assets/icons/login-banner2.svg'
import cardCaricon from "./assets/icons/car_yellow_fill.svg"
import btnArrowIcon from "./assets/icons/ArrowFatLinesRight.svg"
import btnPersonIcon from "./assets/icons/people_yellow_outline.svg";
import userIcon from "./assets/icons/login-user.svg";
import cardPersonIcon from "./assets/icons/people_yellow_fill.svg"
import Whatsapp from './assets/icons/whatsapp.svg'
import SecurityLogin from './assets/icons/security-login.svg'


const IntroScreen = ({ setSecondHome, setHeaderBack }) => {
  let introscreen = true
  // const [redirectedUser, setRedirectedUser] = useState(true)
  const [location, setlocation] = useState('')
  // const qrDataParams = new URLSearchParams(useLocation().search);
  let navigate = useNavigate()

  const vehicleIssues = useCallback(() => {
    if (!window.sessionStorage.getItem("vehicleIssues")) {
      getVehicleIssues()
        .then(res => {
          if (res) {
            let issues = []
            res.data.forEach(issue => {
              const vehicleIssue = {
                issueType: issue.issueType,
                issueName: issue.name,
                issueId: issue.sid
              }
              issues.push(vehicleIssue)
            })
            window.sessionStorage.setItem("vehicleIssues", JSON.stringify(issues))
          }
        })
    }
  }, [])

  useEffect(() => {
    setHeaderBack(false)
    sessionStorage.getItem("user") && JSON.parse(sessionStorage.getItem("user")).authenticated ? navigate('/home') : navigate('/')
    setSecondHome(false)
  }, [])

  useEffect(() => {
    // if (redirectedUser) {
    //   if (qrDataParams.get('qr')) {
    //     const userInfo = {
    //       userID: '',
    //       name: '',
    //       contact: '',
    //       email: '',
    //       flatNumber: '',
    //       societyId: qrDataParams.get('qr') ? qrDataParams.get('qr') : '',
    //       societyName: qrDataParams.get('name') ? qrDataParams.get('name') : '',
    //       role: '',
    //       token: '',
    //       vehicleDetails: []
    //     }
    //     sessionStorage.setItem("user", JSON.stringify(userInfo));
    //     setlocation(userInfo.societyName)
    //     setRedirectedUser(false);
    //   }
    // }
    vehicleIssues()
  }, [vehicleIssues])

  const cardDetails = [
    {
      id: 2,
      cardIcon: cardCaricon,
      cardHeading: 'Notify an issue to owner',
      cardDetail: 'Select this to inform issue with owner',
      buttonText: 'Notify Issue Now',
      buttonAlt: 'arrow icon',
      buttonIcon: btnArrowIcon,
      buttonLink: '/SecondHomeScreen',
      intro: true,
    },
  ]

  // if (introscreen) {
  //   setSecondHome(false)
  // }

  const pageVariants = {
    initial: {
      // opacity: 0,
      x: "100vw",
      // scale: 0.8
    },
    in: {
      // opacity: 1,
      x: 0,
      // scale: 1
    },
    out: {
      // opacity: 0,
      x: "-100vw",
      // scale: 1.2
    }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5
  };

  return (
    <motion.div
      initial='initial'
      animate='in'
      exit='out'
      variants={pageVariants}
      transition={pageTransition}
      className='homescreen-container'>
      {/* <Header headerBack={false} society={location} /> */}
      <div className="intro-image-container d-flex justify-content-center align-items-center">
        <img src={loginBanner} alt="" style={{ maxWidth: "100%" }} />
      </div>
      <div className="services-user">
        <button className='login-btn' onClick={()=> navigate("/login")}>
          User Login 
          <div className="btn-icon">
            <img src={userIcon} />
          </div>
        </button>
        {
          cardDetails.map((card) => (
            <Card card={card} key={card.id} setSecondHome={setSecondHome} />
          ))
        }
        <div className='buttons'>
          {/* <button className='introscreen-service-button btn-1'>
            <h5 className='btn-text mb-0'>
              Notify Issue Now
            </h5>
            <img src={Whatsapp} />
          </button> */}

         {/* <button className='introscreen-service-button btn-2'>
            <h5 className='btn-text mb-0'>
              Security Login
            </h5>
            <img src={SecurityLogin} />
          </button> */}
        </div>
      </div>
    </motion.div>
  )
}

export default IntroScreen
