/* eslint-disable */

//Components/Dependencies
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';

//CSS
import './components/LoginOwner/LoginOwner.css'
import { useNavigate } from 'react-router-dom';
import { userLogin } from "./api/Auth";

//Images/Icons
import loginImage from './assets/icons/Login-image.svg'
import greenCheckIcon from './assets/icons/verified-green-check-icon.svg'

const LoginOwner = ({ setHeaderBack, setBackBtnText }) => {
  const [disable, setDisable] = useState(true)
  const [mobileNumber, setMobileNumber] = useState("");
  // const [flatNumber, setFlatNumber] = useState("");
  const [validLength, setValidLength] = useState(false)
  let navigate = useNavigate()
  setHeaderBack(true)
  setBackBtnText("")
  
  useEffect(() => {
    mobileNumber !== '' ? setDisable(false) : setDisable(true)
  }, [mobileNumber])

  useEffect(() => {
    sessionStorage.getItem("user") && JSON.parse(sessionStorage.getItem("user")).authenticated ? navigate('/home') : null
  }, [])

  const submitLogin = (e) => {
    e.preventDefault();
    userLogin(mobileNumber)
      .then(res => res ? navigate('/verify', {state: mobileNumber} ) : alert("Check your number again"))
      // .then(res =>  navigate('/verify', `${mobileNumber}`))
  }

  const handleLength = (length) => {
    if (length === 10) {
      setValidLength(true)
    } else {
      setValidLength(false)
    }
  }

  const pageVariants = {
    initial: {
      // opacity: 0,
      x: "100vw",
      // scale: 0.8
    },
    in: {
      // opacity: 1,
      x: 0,
      // scale: 1
    },
    out: {
      // opacity: 0,
      x: "-100vw",
      // scale: 1.2
    }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5
  };

  return (
    <motion.div
      initial='initial'
      animate='in'
      exit='out'
      variants={pageVariants}
      transition={pageTransition}
      className='homescreen-container'>
      {/*<div className="login-image">
        <img src={loginImage} alt="" />
      </div> */}
      <div className="login-content d-flex flex-column align-items-start">
        <h1 className="login-heading">
          Login
        </h1>
        <p className='login-subheading'>
        Please enter your mobile number to login
        </p>
        <form className="login-form d-flex flex-column align-items-center" onSubmit={submitLogin}>
          <div className="input-fields d-flex flex-column align-items-start">
            <input type="number" placeholder='Enter Mobile Number' className='login-input' id='vehicle-number' autoComplete="off" value={mobileNumber} onChange={(e) => { setMobileNumber(e.target.value); handleLength(e.target.value.length) }} />
            {
              validLength ? (
                <div className="verified-icon">
                  <img src={greenCheckIcon} alt="" />
                </div>
              ) : ('')
            }
          </div>
          <div className='login-checkbox-container d-flex'>
            <label htmlFor="#login-check" className="checkbox-label termsCheck">
              By clicking, I agree to the <a className='custom-links' href={`${process.env.REACT_APP_TNC}`}>Terms of use</a> and <a className='custom-links' href={`${process.env.REACT_APP_PP}`}>Privacy Policy</a>
            </label>
          </div>
          <button type="submit" className="primary-submit-btn d-flex justify-content-center" disabled={disable}><p>Get OTP</p></button>
        </form>
      </div>
    </motion.div>
  )
}

export default LoginOwner
