// import { useState, useEffect } from 'react'
import carIcon from '../../assets/icons/car-icon.svg'
import carCardIcon from '../../assets/icons/car-card-icon.svg'
import carSmallIcon from '../../assets/icons/car-icon-small.svg'
import ruppeeIcon from '../../assets/icons/currency-inr-icon.svg'
import rightArrow from '../../assets/icons/right-arrow.svg'
import deleteIcon from '../../assets/icons/delete-icon.svg'

import { payLater } from '../../api/BookingsApi'

//CSS
import './CarCard.css'
// import { useEffect } from 'react'

import { Link } from 'react-router-dom'
import { useState } from 'react'
import BottomModal from '../BottomModal/BottomModal'


const CarCard = ({
  subscription,
  viewMoreDetails,
  packageDetails,
  condBooking,
  selectActive,
  vehicleComp,
  MChangePackage,
  car,
  setPackageSelected
}) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [modalData, setModalData] = useState([])

  const handleShow = (Data) => {
    // MData = Data;
    setDisplayModal(true)
    setModalData(Data)
  };

  const packages= car.type==='FOUR_WHEELER'?  JSON.parse(sessionStorage.getItem("carPackages")) : JSON.parse(sessionStorage.getItem("bikePackages"))
 
  const setPackage = (id)=> {
    packages.forEach(item => {
     if (item.packageId === id) return  sessionStorage.setItem('packageSelected', JSON.stringify(item))
   })
 }
  const activePackagefun = (id) => {
    let active = document.getElementsByClassName(`active-package`)
    if (active.length !== 0) {
      [...active].forEach(activeEl => {
        activeEl.classList.remove('active-package')
      })
    }
    document.getElementById(`package-check-${id}`).checked ?
      (document.getElementById(`package-${id}`).classList.add('active-package'), setPackage(id))
      :( document.getElementById(`package-${id}`).classList.remove('active-package'))
      
  }

  const skeletonCar = 'car'
  // const skeletonBike = 'bike'
  function Skeleton(type) {
    return (<div className={`skeleton ${type}`}></div>)
  }

  return (
    <>
      {/* {condBooking ? Skeleton(skeletonCar) : ''} */}
      <div id={`${MChangePackage ? `package-${car.id}` : ''}`} className={`${condBooking ? 'booking-card-container' : ''} ${MChangePackage ? 'card-border' : ''} ${vehicleComp ? 'small-border' : 'car-card-container'}`}>
        <div className={`car-card-wrapper d-flex align-items-center justify-content-between ${condBooking ? 'car-details-level' : ''} ${vehicleComp ? 'car-padding-wrapper' : ''}`}>
          <div className="car-card-left">
            <div className="car-icon">
              {
                subscription ? (
                  <img src={(car.type === 'FOUR_WHEELER' || car.CHeading==='Daily Car Wash') ? carCardIcon : '/bike.svg'} alt="" />
                ) : (
                  car.CPriceCenter ? (
                    <img src={(car.type === 'FOUR_WHEELER' || car.CHeading==='Daily Car Wash') ? carCardIcon : '/bike.svg'} alt="" />
                  ) : (
                    <img src={(car.type === 'FOUR_WHEELER' || car.CHeading==='Daily Car Wash') ? carCardIcon : '/bike.svg'} alt="" />
                  )
                )
              }
            </div>
          </div>
          <div className={`car-card-center d-flex flex-column align-items-start ${condBooking ? 'booking-card-center' : ''} ${subscription || vehicleComp ? 'mar-0' : ''} ${vehicleComp ? 'pad-right-big' : ''}`}>
            <h4 className="car-card-heading">
              {car.CHeading}
            </h4>
            <p className="car-card-subheading">
              {car.CSubheading}
            </p>
            {
              car.CExtraButton ? (
                <p className="car-card-details" onClick={() => handleShow(packageDetails)}>
                  {car.CExtraButtonText}
                </p>
              ) : ('')
            }
            {
              car.CPriceCenter ? (
                <>
                  <div className='car-card-amount d-flex'>
                    <div className={`${car.CPriceCenter ? 'small-icon' : ''} price-icon`}>
                      <img src={ruppeeIcon} alt="" />
                    </div>
                    <p className="car-card-price">
                      {car.CPrice}
                    </p>
                  </div>
                </>
              ) : ('')
            }
          </div>
          <div className="car-card-right d-flex align-items-center">
            {
              car.CRightPrice ? (
                <>
                  <Link to={car.ButtonLink} onClick={() => sessionStorage.setItem('packageSelected', JSON.stringify(packageDetails))} className='d-flex align-items-center'>
                    <div className="price-icon">
                      <img src={ruppeeIcon} alt="" />
                    </div>
                    <h4 className="price-amount">{car.CPrice}</h4>
                    <div className="right-arrow">
                      <img src={rightArrow} alt="" />
                    </div>
                  </Link>
                </>
              ) : ('')
            }
            {
              condBooking ? (
                <button className={`primary-pay-btn d-flex align-items-center justify-content-center ${car.paymentComplete ? 'complete' : 'active'}`}><p onClick={car.paymentComplete ? null : () => { payLater(car.id) }}>{car.paymentComplete ? 'Paid' : (<>{`Pay Rs ${car.CPrice}`}</>)}</p></button>
              ) : ('')
            }
            {
              car.CRightExtraButton ? (
                <p className="car-card-details" onClick={() => handleShow(viewMoreDetails)}>
                  {car.CExtraButtonText}
                </p>
              ) : ('')
            }{
              car.CPriceCenter ? (
                <label htmlFor={`package-check-${car.id}`} className="checkbox-label package-checkbox-container">
                  <input type="radio" name="package" id={`package-check-${car.id}`} onChange={() => activePackagefun(car.id)} />
                  <span className="custom-checkmark"></span>
                </label>
              ) : ('')
            }{
              vehicleComp ? (
                <div className="delete-btn" onClick={() => handleShow(car.MData)}>
                  <img src={deleteIcon} alt="" />
                </div>
              ) : ('')
            }
          </div>
        </div>
        {
          condBooking ? (
            <>
              <div className={`car-onboard-level d-flex align-items-center justify-content-between ${car.bookingComplete ? 'complete-onBoard' : 'active-onBoard'}`}>
                <div className="date-1-container">
                  <p className="date-heading">Start Date</p>
                  <p className="date-value">{car.onBoardStart}</p>
                </div>
                <div className="date-2-container">
                  <p className="date-heading">End Date</p>
                  <p className="date-value">{car.onBoardEnd}</p>
                </div>
              </div>
              <div className={`car-internal-level d-flex align-items-center justify-content-between ${car.bookingComplete ? 'complete-internal' : 'active-internal'}`}>
                <div className="date-1-container">
                  <p className="date-heading">Internal Date 1</p>
                  <p className="date-value">{car.internalStart}</p>
                </div>
                <div className="date-2-container">
                  <p className="date-heading">Internal Date 2</p>
                  <p className="date-value">{car.internalEnd}</p>
                </div>
              </div>
            </>
          ) : ('')
        }
      </div>
      <BottomModal
        displayModal={displayModal}
        setDisplayModal={setDisplayModal}
        modalData={modalData}
        vehicleComp={true}
      />
    </>
  )
}

export default CarCard