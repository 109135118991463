import './IssueCard.css'

const IssueCard = ({ issueDetails }) => {
  // console.log(issueDetails);
  return (
    <>
      {
        issueDetails.map((issue) => (
          <div className={`issue-card-container ${issue.issueCompleted ? 'issue-completed' : ''}`} key={issue.id}>
            <div className="issue-card-wrapper d-flex justify-content-between align-items-center">
              <h4 className="issue-name">{issue.issueName}</h4>
              <p className='issue-date'>{issue.issueDate}</p>
            </div>
          </div>
        ))
      }
    </>
  )
}

export default IssueCard
