
import { useState, useEffect, useCallback } from 'react'
import CarCard from './components/Car-CardComponent/CarCard';
import ruppeeIcon from './assets/icons/currency-inr-icon.svg'
import infoIcon from './assets/icons/info-outline-icon.svg'
import './components/Subsription/Subscription.css'
import CarBox from './components/CarBoxComponent/CarBox';
import arrowDown from './assets/icons/down-arrow.svg'
import BottomModal from './components/BottomModal/BottomModal'
// import moment from 'moment';
import { getBookingDates, getInternaldates, saveBooking, saveTransaction } from './api/BookingsApi'
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const Subscription = ({ setHeaderBack, setBackBtnText }) => {
  setHeaderBack(true)
  setBackBtnText('Subscription Details')

  const subscription = true;
  let navigate = useNavigate()
  const [paybtnDisabled, setPaybtnDisabled] = useState(true);
  const [datebtnDisabled, setDatebtnDisabled] = useState(true);
  const [displayModal, setDisplayModal] = useState(false);
  const [modalData, setModalData] = useState([])
  const [onBoardAct, setOnBoardAct] = useState(true)
  const [bookingType, setbookingType] = useState("")
  const [paymentSuccess, setPaymentSuccess] = useState(false)

  const [onboardingDate, setOnboardingDate] = useState({ date1: '', dateLabel1: '', dateLabel2: '', date2: '' })
  const [selectedStartDate, setSelectedStartDate] = useState(onboardingDate.date1)
  const [internalDates, setInternalDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState({ dateLabel: '', dateSlot: '' })

  const [vehicles, setVehicles] = useState([{ carName: '', carNumber: '', id: '' }])
  const [packageSelected, setPackageSelected] = useState(JSON.parse(sessionStorage.getItem('packageSelected')))
  const [vehicleSelected, setVehicleSelected] = useState([])
  const [packageDetails, setPackageDetails] = useState([])
  
  const [payNowBooking, setPayNowBooking] = useState({
    // customerName: "",
    // customerContact: "",
    // customerEmail: "",
    // appId: "",
    // cfTokenMessage: "Token generated",
    // cfToken: "",
    // orderId: "",
    // orderAmount: 0,
    // orderCurrency: "INR"
  })
  // const [disable, setDisable] = useState(true)
  let dayDates = []
  let dateClass = []

  function getDayOfWeek(date) {
    const dayOfWeek = new Date(date).getDay();
    return isNaN(dayOfWeek) ? null :
      ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][dayOfWeek];
  }

  function getMonthofYear(month) {
    return isNaN(month) ? null :
      ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][month];
  }

  let thisDate = new Date()
  let thisMonth = new Date()
  let thisYear = new Date()

  // today = today.getDate()
  thisMonth = thisMonth.getMonth()
  thisYear = thisYear.getFullYear()

  //On Boarding Dates Setup 
  let onBoardingMonthFirst
  let onBoardingMonthSecond
  let onBoardingDateFirst
  let onBoardingDateSecond
  if (thisDate < 15) {
    onBoardingMonthFirst = getMonthofYear(thisMonth)
    onBoardingMonthSecond = getMonthofYear(thisMonth)
    onBoardingDateFirst = '15'
    onBoardingDateSecond = '01'
  }
  if (thisDate >= 15) {
    onBoardingMonthFirst = getMonthofYear(thisMonth + 1)
    onBoardingMonthSecond = getMonthofYear(thisMonth + 1)
    onBoardingDateFirst = '01'
    onBoardingDateSecond = '15'
  }

  let changeDate = {
    MHeading: 'Select Day and Date',
    MSubheading: 'Please select your preferred day & date, 2nd internal cleaning date will auto select',
    MButtonText: 'Done',
    dateChange: true,
    dateChangeDetails: internalDates,
    dateIds: dateClass,
  }

  const subscriptionApis = useCallback(() => {
    packageSelected.packageVehicleType === 'FOUR_WHEELER' ?
      setVehicles(JSON.parse(sessionStorage.getItem("myVehicles")))
      :
      setVehicles(JSON.parse(sessionStorage.getItem("myBikes")))
    // // Get Vehicle by type
    // getVehicleByType("FOUR_WHEELER")
    //   .then((res) => {
    //     if (res) {
    //       let carBoxDetails = []
    //       res.data.forEach(vehicle => {
    //         const vehicleDetails = {
    //           id: vehicle.sid,
    //           carName: vehicle.model,
    //           carNumber: vehicle.registrationNumber
    //         }
    //         carBoxDetails.push(vehicleDetails);
    //       })
    //       setVehicles(carBoxDetails)
    //     }
    //   })

  }, [packageSelected])

  useEffect(() => {
    // Get onboarding dates
    getBookingDates()
      .then(res => {
        if (res) {
          setOnboardingDate({
            date1: res.onBoardingDates[0].date,
            dateLabel1: res.onBoardingDates[0].dateLabel,
            dateLabel2: res.onBoardingDates[1].dateLabel,
            date2: res.onBoardingDates[1].date,
          })
          InternalDates(res.onBoardingDates[0].date)
        }
      })
  }, [])

  const InternalDates = (onboardFrom) => {
    setSelectedStartDate(onboardFrom)
    // Get Internal cleaning Dates
    getInternaldates(onboardFrom)
      .then(res => {
        if (res) {
          setInternalDates(res.availableDates)
        }
      })
  }

  useEffect(() => {
    setPackageSelected(JSON.parse(sessionStorage.getItem('packageSelected')))
    setPaymentSuccess(false)
  }, [(sessionStorage.getItem('packageSelected'))])

  useEffect(() => {
    subscriptionApis()
  }, [subscriptionApis])

  useEffect(() => {
    if (vehicleSelected.length === 0) return
    vehicleSelected[0].vehicleId ? setDatebtnDisabled(false) : setDatebtnDisabled(true)
  }, [vehicleSelected])

  useEffect(() => {
    packageSelected.packageVehicleType === 'TWO_WHEELER' &&
      !datebtnDisabled ? setPaybtnDisabled(false) : setPaybtnDisabled(true)
  }, [datebtnDisabled])

  useEffect(() => {
    selectedDate.dateSlot ? setPaybtnDisabled(false) : setPaybtnDisabled(true)
  }, [selectedDate])


  useEffect(() => {

    let bookings = []
    if (bookingType !== "") {
      if (packageSelected.packageVehicleType === 'TWO_WHEELER') {
        bookings = vehicleSelected.map(vehicle => {
          return {
            packageDetails: {
              name: packageSelected.MHeading,
              vehicleType: 'TWO_WHEELER',
              registrationNumber: vehicle.vehicleNumber,
              vehicleId: vehicle.vehicleId,
              price: packageSelected.price,
              addOns: [],
            },
            startDate: onboardingDate.date1,
            timings: '08:00',
            bookingType: bookingType,
            paymentGateway: "RAZORPAY"
          }
        })
      } else {
        bookings = vehicleSelected.map(vehicle => {
          return {
            packageDetails: {
              name: packageSelected.MHeading,
              vehicleType: 'FOUR_WHEELER',
              registrationNumber: vehicle.vehicleNumber,
              vehicleId: vehicle.vehicleId,
              price: packageSelected.price,
              addOns: [],
            },
            slots: [
              { slot: selectedDate.dateSlot },
              { slot: selectedInternalDate2 }
            ],
            startDate: onboardingDate.date1,
            timings: '08:00',
            bookingType: bookingType,
            paymentGateway: "RAZORPAY"
          }
        })
      }
      // setbookingDetails(booking)
        saveBooking(bookings[0])
        .then(resp => {
          console.log(resp)
          if (resp && resp.data.booking.bookingType === "PAY_NOW") {
            const razorpayDetails = resp.data.razorPayResponse;
            setPayNowBooking(razorpayDetails)
            // razorPay(razorpayDetails) // API Call
            //   .then(res => {
            //     console.log('TRXN SUCCESS', res);
            //     setPaymentSuccess(true)
            //   })
            razorPayGateway(razorpayDetails)
            setbookingType('')
          }
        })
    }

  }, [packageSelected, vehicleSelected, selectedDate.dateSlot, onboardingDate, bookingType])

  const razorPayGateway = (orderDetails) => {

    const userInfo = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {}
    var options = {
      key: `${process.env.REACT_APP_RZP_KEY}`,
      amount: orderDetails.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: orderDetails.currency,
      name: userInfo.name,
      description: "Payment To Servii",
      image: "servii-logo-full.png",
      order_id: orderDetails.order_id,
      handler: function (response) {
        const rzpResponse = {
          orderId: response.razorpay_order_id,
          paymentId: response.razorpay_payment_id,
          signature: response.razorpay_signature
        }
        saveTransaction(rzpResponse)
          .then(res => {
            if (res) {
              // console.log('TRXN SUCCESS', res);
              setPaymentSuccess(true)
            }
          })
      },
      prefill: {
        name: userInfo.name,
        email: userInfo.email,
        contact: userInfo.contact
      },
      notes: {
        address: "Razorpay Corporate Office"
      },
      theme: {
        color: "#3399cc"
      }
    };

    var rzp1 = new window.Razorpay(options);

    rzp1.on('payment.failed', function (response) {
      alert(`${response.error.code} : ${response.error.description} Please Retry`)
    });
    rzp1.open();
  }


  //Set Internal Date
  let internalDate1 = null
  let internalMonth1 = null
  let internalDate2 = null
  let internalDay1 = null
  let internalDay2 = null
  let selectedInternalDate2 = ''

  if (selectedDate.dateSlot !== '') {
    let monthPart2
    let yearPart2

    let splitDayDate = selectedDate.dateSlot.split("-")
    // let splitDate = splitDayDate[1]
    // let splitDateParts = splitDate.split('-')

    let yearPart1 = splitDayDate[0]
    let monthPart1 = splitDayDate[1]
    let datePart1 = splitDayDate[2]

    internalDay1 = getDayOfWeek(selectedDate.dateSlot)
    internalDate1 = datePart1 + '-' + monthPart1 + '-' + yearPart1

    internalMonth1 = getMonthofYear(parseInt(monthPart1 - 1))
    let datePart2 = parseInt(datePart1) + 14

    if (
      internalMonth1 === 'Jan' ||
      internalMonth1 === 'Mar' ||
      internalMonth1 === 'May' ||
      internalMonth1 === 'Jul' ||
      internalMonth1 === 'Aug' ||
      internalMonth1 === 'Oct' ||
      internalMonth1 === 'Dec'
    ) {
      if (datePart2 >= 31) {
        datePart2 = datePart2 - 31
        monthPart2 = parseInt(monthPart1) + 1

        if (internalMonth1 === 'Dec') {
          yearPart2 = parseInt(yearPart1) + 1
          monthPart2 = 1

        } else {
          yearPart2 = yearPart1
        }

      } else {
        yearPart2 = yearPart1
        monthPart2 = parseInt(monthPart1)
      }

    } else if (
      internalMonth1 === 'Apr' ||
      internalMonth1 === 'Jun' ||
      internalMonth1 === 'Sep' ||
      internalMonth1 === 'Nov'
    ) {
      if (datePart2 >= 30) {
        datePart2 = datePart2 - 30
        monthPart2 = parseInt(monthPart1) + 1
        yearPart2 = yearPart1

      } else {
        monthPart2 = parseInt(monthPart1)
        yearPart2 = yearPart1
      }

    } else if (internalMonth1 === 'Feb') {
      let endDate
      if (thisYear % 4 === 0) {
        endDate = 29
      } else {
        endDate = 28
      }

      if (datePart2 > endDate) {
        datePart2 = datePart2 - endDate
        monthPart2 = parseInt(monthPart1) + 1
        yearPart2 = yearPart1
      } else {
        monthPart2 = parseInt(monthPart1)
        yearPart2 = yearPart1
      }

    }
    let tempDate2 = yearPart2 + '-' + monthPart2 + '-' + datePart2
    internalDay2 = getDayOfWeek(tempDate2)
    internalDate2 = (datePart2 < 10 ? '0' + datePart2 : datePart2) + '-' + (monthPart2 < 10 ? '0' + monthPart2 : monthPart2) + '-' + yearPart2

    selectedInternalDate2 = yearPart2 + '-' + (monthPart2 < 10 ? '0' + monthPart2 : monthPart2) + '-' + (datePart2 < 10 ? '0' + datePart2 : datePart2)
  }
  // console.log(selectedDate);

  const handleShow = (Data) => {
    setDisplayModal(true)
    setModalData(Data)
  };
const packages = packageSelected.packageVehicleType==='FOUR_WHEELER'?  JSON.parse(sessionStorage.getItem("carPackages")) : JSON.parse(sessionStorage.getItem("bikePackages"))
const ChangePackageDetails = {
    MHeading: 'Change Package',
    MSubheading: 'Please select a package to change',
    MButtonText: 'Done',
    MChangePackage: true,
    ChangePackage: packages.map(item => {
      return {
        id: item.packageId,
        CHeading: item.MHeading,
        CSubheading: item.packageWashinfo,
        CExtraButton: false,
        CRightPrice: false,
        CRightExtraButton: false,
        CPriceCenter: true,
        CPrice: item.MPrice,
      }
    })
  }

  vehicleSelected.map(vehicle => vehicle)
  const AmountInfo = {
    MHeading: 'Amount to Pay',
    Info: true,
    // totalAmount: vehicle.vehicleId ? packageSelected.price : '0',
    totalAmount: vehicleSelected.length > 0 ? packageSelected.price * vehicleSelected.length : '0',

    // Need to change as per the vehicle selected - single
    AmountDetails: vehicleSelected.map(vehicle => {
      return {
        id: vehicle.vehicleId,
        carName: vehicle.vehicleName ? vehicle.vehicleName : "--",
        carPrice: vehicle.vehicleId ? packageSelected.price : '0',
      }
    })
  }

  const ProceedPay =  {
      MHeading: 'Proceed to Pay',
      totalAmount: packageSelected.price * vehicleSelected.length,
      Info: false,
      ProceedPay: true,
      // Need to change as per the vehicle selected - single
      AmountDetails: vehicleSelected.map(vehicle => {
        return {
          id: vehicle.vehicleId,
          carName: vehicle.vehicleName ? vehicle.vehicleName : "--",
          carPrice: vehicle.vehicleId ? packageSelected.price : '0',
        }
      })
  }

  const carCardDetails = [
    {
      id: 1,
      CHeading: packageSelected.MHeading,
      CSubheading: packageSelected.packageWashinfo,
      CExtraButton: false,
      CExtraButtonText: 'View Details',
      CRightPrice: false,
      CRightExtraButton: true,
      CPriceCenter: false,
      CPrice: packageSelected.MPrice,
      ButtonLink: '/subscription',
      type: packageSelected.packageVehicleType,
      viewDetailsData: {
        MHeading: packageSelected.MHeading,
        MButtonText: 'Next',
        MBottomPrice: true,
        subscription: subscription,
        MPrice: packageSelected.MPrice,
        packageList: true,
        packageListHeading: 'Package contains following things :-',
        packageListDetials: packageSelected.packageListDetials
      }
    }
  ]

  const pageVariants = {
    initial: {
      // opacity: 0,
      x: "100vw",
      // scale: 0.8
    },
    in: {
      // opacity: 1,
      x: 0,
      // scale: 1
    },
    out: {
      // opacity: 0,
      x: "-100vw",
      // scale: 1.2
    }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.3
  };

  const handleRedirect = (page) => {
    navigate(page)
  }

  return (
    <>
      <motion.div
        initial='initial'
        animate='in'
        exit='out'
        variants={pageVariants}
        transition={pageTransition}
        className='homescreen-container'>
        <div className='subscription-wrapper'>
          <div className="carpackage-container">
            <div className="carpackage-header-container">
              <div className="carpackage-header d-flex justify-content-between">
                <h1 className="section-heading">{packageSelected.packageLabel}</h1>
                {/* <p className="extra-yellow-btn" onClick={() => handleRedirect('/addvehicle')}>+ Add Vehicle</p> */}
              </div>
              <p className="carpackage-subheading">
                Select a package as per your requirement
              </p>
            </div>
            <div className="cards-container d-flex">
              <div className="card-wrapper d-inline-flex">
                {/* Car cards import */}
                <CarBox carData={vehicles} vehicleSelected={vehicleSelected} setVehicleSelected={setVehicleSelected} vehicleType={packageSelected.packageVehicleType === 'FOUR_WHEELER' ? 'FOUR_WHEELER' : 'TWO_WHEELER'} />
              </div>
            </div>
          </div>
          <div className="selected-package-container">
            <div className="selected-header d-flex justify-content-between align-items-center">
              <h1 className="section-heading">Selected Package</h1>
              <p className="extra-yellow-btn" onClick={() => handleShow(ChangePackageDetails)}>Change Package</p>
            </div>
            {
              carCardDetails.map((car) => (
                <CarCard
                  car={car}
                  displayModal={displayModal}
                  setDisplayModal={setDisplayModal}
                  MData={modalData}
                  setModalData={setModalData}
                  subscription={subscription}
                  viewMoreDetails={carCardDetails[0].viewDetailsData}
                  key={car.id}
                  setPackageSelected={setPackageSelected}
                />

              ))
            }
          </div>
          <div className="onboarding-container">
            <div className="onboarding-header d-flex justify-content-between">
              <h1 className="section-heading">Onboarding Date</h1>
            </div>
            <div className="onboarding-btns d-flex">
              <button className={`d-flex justify-content-center onboard-btn  ${onBoardAct ? ('primary-submit-btn') : ('secondary-submit-btn')}`} onClick={() => { setOnBoardAct(true); InternalDates(onboardingDate.date1) }} disabled={datebtnDisabled}>
                <p>{onboardingDate.dateLabel1}</p>
              </button>
              <button className={`d-flex justify-content-center onboard-btn ${onBoardAct ? ('secondary-submit-btn') : ('primary-submit-btn')}`} onClick={() => { setOnBoardAct(false); InternalDates(onboardingDate.date2) }} disabled={datebtnDisabled}>
                <p>{onboardingDate.dateLabel2}</p>
              </button>
            </div>
            {packageSelected.packageVehicleType === 'FOUR_WHEELER' &&
              <div className="optional-container">
                <div className="optional-header d-flex justify-content-between">
                  <h1 className="section-heading">Internal Cleaning Dates {vehicleSelected.vehicleName}</h1>
                </div>
                <div className="optional-btns d-flex">
                  <button className="select-date-btn secondary-submit-btn d-flex justify-content-center optional-btn" onClick={() => handleShow(changeDate)} disabled={datebtnDisabled}>
                    {selectedDate.dateSlot === '' ? (<p>Select Date</p>) : (<>{internalDay1}, {internalDate1} </>)}
                    <span className="arrow-down-icon"><img src={arrowDown} alt="" /></span>
                  </button>
                  <button className="select-date-btn secondary-submit-btn d-flex justify-content-center optional-btn" disabled={datebtnDisabled}>
                    {selectedDate.dateSlot === '' ? (<>2nd Date</>) : (<>{internalDay2}, {internalDate2} </>)}
                  </button>
                </div>
              </div>
            }
          </div>
        </div>
        <BottomModal
          displayModal={displayModal}
          setDisplayModal={setDisplayModal}
          modalData={modalData}
          setSelectedDate={setSelectedDate}
          setbookingType={setbookingType}
        />
      </motion.div>
      <div className="subscription-footer d-flex align-items-center justify-content-between">
        <div className="price-section">
          <div className="price-box d-flex flex-column">
            <div className="price-box-header d-flex align-items-center">
              <p>Amount to Pay</p>
              <div className='info-icon' onClick={() => handleShow(AmountInfo)}>
                <img src={infoIcon} alt="" />
              </div>
            </div>
            <div className="amount d-flex flex-row">
              <div className="price-icon-m">
                <img src={ruppeeIcon} alt="" />
              </div>
              <h6>{vehicleSelected.length > 0 ? packageSelected.price * vehicleSelected.length : "0/month"}</h6>
            </div>
          </div>
        </div>
        <div className="btn-container">
          <button className='primary-submit-btn d-flex justify-content-between subscription-btn' disabled={paybtnDisabled} onClick={() => handleShow(ProceedPay)} ><p>Proceed to Pay</p></button>
        </div>
      </div>
    </>
  )

}
export default Subscription