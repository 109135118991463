import { Link } from 'react-router-dom'
import bookmarkOutlineIcon from '../../assets/icons/bookmark-outline-icon.svg'
import bookmarkFillIcon from '../../assets/icons/bookmark-fill-icon.svg'
import handymanOutlineIcon from '../../assets/icons/handyman-outline-icon.svg'
import handymanFillIcon from '../../assets/icons/handyman-fill-icon.svg'
import userOutlineIcon from '../../assets/icons/user-outline-icon.svg'
import userFillIcon from '../../assets/icons/user-fill-icon.svg'
import './Footer.css'
const Footer = ({ activeId }) => {

  const footerDetails = [
    {
      id: 1,
      footerIcon: bookmarkOutlineIcon,
      footerText: 'My Bookings',
      active: false,
      activeIcon: bookmarkFillIcon,
      footerLink: '/mybookings'
    },
    {
      id: 2,
      footerIcon: handymanOutlineIcon,
      footerText: 'Home',
      active: false,
      activeIcon: handymanFillIcon,
      footerLink: '/home'
    },
    {
      id: 3,
      footerIcon: userOutlineIcon,
      footerText: 'Profile',
      active: false,
      activeIcon: userFillIcon,
      footerLink: '/myprofile'
    },
  ]

  // console.log(activeId, footerDetails)

  footerDetails.forEach((item) => {
    if (item.id === activeId) {
      item.active = true;
    }
  }
  )

  // console.log(footerDetails)

  return (
    <div className="homescreen-footer">
      <div className="footer-content d-flex justify-content-between">
        {
          footerDetails.map((footer) => (
            <Link to={footer.footerLink} className={footer.active ? 'active-icon d-flex flex-column align-items-center' : 'content d-flex flex-column align-items-center'} key={footer.id}>
              <div className="footer-icon">
                <img src={footer.active ? footer.activeIcon : footer.footerIcon} alt="" />
              </div>
              <p className={`footer-text ${footer.active ? 'active-text' : ''}`}>
                {footer.footerText}
              </p>
            </Link>
          ))
        }
      </div>
    </div>
  )
}

export default Footer
