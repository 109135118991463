/* eslint-disable */
import Header from './components/header/Header';
import { useEffect, useState } from 'react';
import './components/VehicleInfo/VehicleInfo.css'
import { useNavigate } from 'react-router-dom'
// import arrowDown from './assets/icons/down-arrow.svg'
import { saveBike, saveVehicle, standardBikeList } from './api/VehiclesApi'
import BottomModal from './components/BottomModal/BottomModal'
import { Dropdown } from 'react-bootstrap';
import { motion } from 'framer-motion';

const BikeInfo = ({ setHeaderBack, setBackBtnText }) => {
  const [vehicleList, setVehicleList] = useState([])
  const [petrol, setPetrol] = useState(false)
  const [diesel, setDiesel] = useState(false)
  const [electric, setElectric] = useState(false)
  const [gas, setGas] = useState(false)
  const [carInfo, setCarInfo] = useState(true)
  const [vehicleNumber, setVehicleNumber] = useState('')
  const [vehicleName, setVehicleName] = useState('')
  const [parkingArea, setParkingArea] = useState('')
  const [parkingNumber, setParkingNumber] = useState('')
  const [fuelType, setFuelType] = useState('')
  const [disable, setDisable] = useState(true)
  const [displayModal, setDisplayModal] = useState(false);
  const [modalData, setModalData] = useState([])
  const [selectCar, setSelectCar] = useState('');
  const [searchVehicle, setSearchVehicle] = useState('')
  const [stdVehicles, setStdVehicles] = useState([])

  setHeaderBack(true)
  setBackBtnText('Bike Details')
  let navigate = useNavigate();

  const handleClick = (props) => {
    switch (props) {
      case 'pet':
        setPetrol(!petrol)
        setDiesel(false)
        setElectric(false)
        setGas(false)
        setFuelType('PETROL')
        break;
      case 'die':
        setPetrol(false)
        setDiesel(!diesel)
        setElectric(false)
        setGas(false)
        setFuelType('DIESEL')
        break;
      case 'ele':
        setPetrol(false)
        setDiesel(false)
        setElectric(!electric)
        setGas(false)
        setFuelType('ELECTRIC')
        break;
      case 'gas':
        setPetrol(false)
        setDiesel(false)
        setElectric(false)
        setGas(!gas)
        setFuelType('GAS')
        break;
      default:
        // console.log(props);
        setPetrol(false)
        setDiesel(false)
        setElectric(false)
        setGas(false)
        break;
    }
  };

  const validateForm = () => {
    (document.getElementById('parking-number').value !== '') && (document.getElementById('vehicle-number').value !== '') ? setDisable(false) : setDisable(true)
    // (vehicleNumber !== '' && vehicleName !== '' && parkingArea !== '' && parkingNumber !== '') ? setDisable(false) : setDisable(true)
  }

  const addvehicle = () => {
    let vehNumberTrimmed = vehicleNumber.replace(/ /g, '')
    const vehicleData = {
      model: vehicleName,
      registrationNumber: vehNumberTrimmed,
      fuelType: fuelType,
      vehicleType: "TWO_WHEELER",
      parkingLot: parkingNumber,
      parkingArea: parkingArea,
      userId: JSON.parse(sessionStorage.getItem('user')).userID
    }
    
    setDisable(true)
    saveBike(vehicleData)
      .then((res) => {
        if (res) {
          if (sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).name) {
            let user = JSON.parse(sessionStorage.getItem("user"))
            user.authenticated = true
            sessionStorage.setItem('user', JSON.stringify(user))
          }
          sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).name ? navigate('/home') : navigate('/userinfo')
        }
        setDisable(false)
      })
  }

  const vehicleModal = {
    MHeading: 'Select Vehicle Model',
    MCustomInput: true,
    vehicleListCond: true
  }

  useEffect(() => {
    standardBikeList()
      .then(res => {
        if (res) {
          setStdVehicles(res.data)
          setVehicleList(res.data)
        }
      })
  }, [])

  useEffect(() => {
    setVehicleList(() => {
      return stdVehicles.filter(vehicle => vehicle.toLowerCase().includes(searchVehicle))
    })
    // console.log(vehicleList);
  }, [searchVehicle])

  const handleShow = (Data) => {
    setDisplayModal(true)
    setModalData(Data)
  };

  const parkingArray = [
    'Ground Floor',
    'Basement 1',
    'Basement 2',
  ]

  const pageVariants = {
    initial: {
      // opacity: 0,
      x: "100vw",
      // scale: 0.8
    },
    in: {
      // opacity: 1,
      x: 0,
      // scale: 1
    },
    out: {
      // opacity: 0,
      x: "-100vw",
      // scale: 1.2
    }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.3
  };


  return (
    <>
      <motion.div
        initial='initial'
        animate='in'
        exit='out'
        variants={pageVariants}
        transition={pageTransition}
        className='homescreen-container'>
        {
          carInfo ? (
            <div className="user-info-wrapper form-wrapper">
              <div className="vehicle-header d-flex flex-column">
                <p className="fuel-type">Fuel Type</p>
                <div className="fuel-btns d-flex">
                  <button className={`d-flex justify-content-center onboard-btn ${petrol ? ('primary-submit-btn') : ('secondary-submit-btn')}`} onClick={() => handleClick('pet')}>Petrol</button>
                  <button className={`d-flex justify-content-center onboard-btn ${electric ? ('primary-submit-btn') : ('secondary-submit-btn')}`} onClick={() => handleClick('ele')}>Electric</button>
                </div>
              </div>
              <form className="login-form d-flex flex-column align-items-center" onChange={validateForm}>
                <div className="input-fields d-flex flex-column align-items-start">
                  <label htmlFor="#vehicle-number">Vehicle No.</label>
                  <input type='text' placeholder='Enter Vehicle Number' className='login-input' id='vehicle-number' autoComplete="off" value={vehicleNumber} onChange={(e) => setVehicleNumber(e.target.value)} />
                </div>
                <div className="input-fields d-flex flex-column align-items-start">
                  <label htmlFor="#vehicle-name">Vehicle Name</label>
                  <button type='button' className='login-input vehicle-field' id='vehicle-name' value={vehicleName} onChange={(e) => setVehicleName(e.target.value)} onClick={() => handleShow(vehicleModal)}>
                    <p className={selectCar ? 'car-selected' : ''}>{selectCar !== '' ? selectCar : 'Enter Vehicle Model'}</p>
                  </button>
                </div>
                <div className="input-fields d-flex flex-column align-items-start">
                  <label htmlFor="#parking-area">Parking Area</label>
                  <Dropdown >
                    <Dropdown.Toggle className={`${parkingArea ? 'parking-selected' : ''}`}>
                      {parkingArea !== '' ? parkingArea : ' Select Parking Area'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {
                        parkingArray.map((park, index) => (
                          <Dropdown.Item title={park} onClick={() => setParkingArea(park)} key={index}>{park}</Dropdown.Item>
                        ))
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="input-fields d-flex flex-column align-items-start">
                  <label htmlFor="#parking-number">Parking Number</label>
                  <input type="text" placeholder='Enter Parking No.' className='login-input' id='parking-number' autoComplete="off" value={parkingNumber} onChange={(e) => { setParkingNumber(e.target.value); setDisable(false) }} />
                </div>
              </form>
            </div>
          ) : (
            <>
              <div className="user-info-wrapper">
                <div className="vehicle-header d-flex flex-column">
                  <p className="fuel-type">Fuel Type</p>
                  <div className="fuel-btns d-flex">
                    <button className={`d-flex justify-content-center onboard-btn ${petrol ? ('primary-submit-btn') : ('secondary-submit-btn')}`} onClick={() => handleClick('pet')}>Petrol</button>
                    <button className={`d-flex justify-content-center onboard-btn ${electric ? ('primary-submit-btn') : ('secondary-submit-btn')}`} onClick={() => handleClick('ele')}>Electric</button>
                  </div>
                </div>
                <form className="login-form d-flex flex-column align-items-center" onChange={validateForm} onSubmit={addvehicle}>
                  <div className="input-fields d-flex flex-column align-items-start">
                    <label htmlFor="#vehicle-number">Vehicle No.</label>
                    <input type='text' placeholder='Enter Vehicle Number' className='login-input' id='vehicle-number' autoComplete="off" value={vehicleNumber} onChange={(e) => setVehicleNumber(e.target.value)} />
                  </div>
                  <div className="input-fields d-flex flex-column align-items-start">
                    <label htmlFor="#vehicle-name">Vehicle Name</label>
                    <input type='text' placeholder='Enter Vehicle Model' className='login-input' id='vehicle-name' autoComplete="off" value={vehicleName} onChange={(e) => setVehicleName(e.target.value)} />
                  </div>
                  <div className="input-fields d-flex flex-column align-items-start">
                    <label htmlFor="#parking-area">Parking Area</label>
                    <input type='text' placeholder='Select Parking Area' className='login-input' id='parking-area' autoComplete="off" value={parkingArea} onChange={(e) => setParkingArea(e.target.value)} />
                  </div>
                  <div className="input-fields d-flex flex-column align-items-start">
                    <label htmlFor="#parking-number">Parking Number</label>
                    <input type="text" placeholder='Enter Parking No.' className='login-input' id='parking-number' autoComplete="off" value={parkingNumber} onChange={(e) => { setParkingNumber(e.target.value) }} />
                  </div>
                </form>
              </div>
              <div className='btn-fixed' >
                <button type="submit" onClick={addvehicle} className="primary-submit-btn d-flex justify-content-center vehicle-submit-btn" disabled={disable}><p>Done</p></button>
              </div>
            </>
          )
        }
        <BottomModal
          displayModal={displayModal}
          setDisplayModal={setDisplayModal}
          modalData={modalData}
          setSearchVehicle={setSearchVehicle}
          setSelectCar={setSelectCar}
          setVehicleName={setVehicleName}
          vehicleList={vehicleList}
        />
      </motion.div>
      <div className='btn-fixed' >
        <button type="submit" onClick={addvehicle} className="primary-submit-btn d-flex justify-content-center vehicle-submit-btn" disabled={disable}><p>Done</p></button>
      </div>
    </>
  )
}

export default BikeInfo
