/* eslint-disable */
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react'
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import IssueCard from './components/IssueCard/IssueCard';
import './components/RaisedIssues/RaisedIssues.css'
import { getMyParkingIssues } from './api/IssuesApi'

const RaisedIssues = ({ setHeaderBack, setBackBtnText }) => {
  const [selectedtab, setSelectedtab] = useState(1)
  const [issueInprocess, setIssueInprocess] = useState([])
  const [issueNew, setIssueNew] = useState([])
  const [issueResolved, setIssueResolved] = useState([])
  const activeId = 3
  setHeaderBack(true)
  setBackBtnText('Parking Issues')

  const handleSelect = (num) => {
    setSelectedtab(num)
  };

  const customDate = (dateString) => {
    dateString = new Date()
    let finalDate;
    finalDate = (dateString.getDate() < 10 ? "0" + dateString.getDate() : dateString.getDate()) + "/" + (dateString.getMonth() < 10 ? "0" + dateString.getMonth() : dateString.getMonth()) + "/" + dateString.getFullYear()
    return finalDate;
  }

  useEffect(() => {
    getMyParkingIssues('NEW')
      .then(res => {
        if (res && res.data) {
          let response = res.data;
          let issueData = []
          response.forEach((issue) => {
            const issueDetails = {
              id: issue.sid,
              issueName: issue.issue.name,
              issueDate: customDate(issue.createdDate)
            }
            issueData.push(issueDetails)
          })
          setIssueNew(issueData)
        }
      })

    getMyParkingIssues('INPROCESS')
      .then(res => {
        if (res && res.data) {
          let response = res.data;
          let issueData = []
          response.forEach((issue) => {
            const issueDetails = {
              id: issue.sid,
              issueName: issue.issue.name,
              issueDate: customDate(issue.createdDate)
            }
            issueData.push(issueDetails)
          })
          setIssueInprocess(issueData)
        }
      })

    getMyParkingIssues('RESOLVED')
      .then(res => {
        if (res && res.data) {
          let response = res.data;
          let issueData = []
          response.forEach((issue) => {
            const issueDetails = {
              id: issue.sid,
              issueName: issue.issue.name,
              issueDate: customDate(issue.createdDate)
            }
            issueData.push(issueDetails)
          })
          setIssueResolved(issueData)
        }
      })
  }, [])

  const pageVariants = {
    initial: {
      // opacity: 0,
      x: "100vw",
      // scale: 0.8
    },
    in: {
      // opacity: 1,
      x: 0,
      // scale: 1
    },
    out: {
      // opacity: 0,
      x: "-100vw",
      // scale: 1.2
    }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.3
  };

  return (
    <>
      <motion.div
        initial='initial'
        animate='in'
        exit='out'
        variants={pageVariants}
        transition={pageTransition}
        className='homescreen-container'>
        <div className="issue-container">
          <div className="tabs-section d-flex">
            <div className={`active-tab d-flex justify-content-center align-items-end ${selectedtab === 1 ? 'selected' : ''}`} onClick={() => handleSelect(1)}>
              <p>Pending</p>
            </div>
            <div className={`completed-tab d-flex justify-content-center align-items-end ${selectedtab === 2 ? 'selected' : ''}`} onClick={() => handleSelect(2)}>
              <p>In Progress</p>
            </div>
            <div className={`completed-tab d-flex justify-content-center align-items-end ${selectedtab === 3 ? 'selected' : ''}`} onClick={() => handleSelect(3)}>
              <p>Completed</p>
            </div>
          </div>
          <div className="issue-wrapper d-flex flex-column">
            {
              selectedtab === 1 ? (
                <>
                  <IssueCard issueDetails={issueNew} />
                </>
              ) : selectedtab === 2 ? (
                <>
                  <IssueCard issueDetails={issueInprocess} />
                </>
              ) : selectedtab === 3 ? (
                <>
                  <IssueCard issueDetails={issueResolved} />
                </>
              ) : ('')
            }
          </div>
        </div>
      </motion.div>
      <Footer activeId={activeId} />
    </>
  )
}

export default RaisedIssues