import axios from 'axios'

// let userToken = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).token : undefined

// const headers = {
//   "Access-Control-Allow-origin": "*",
//   'Content-Type': 'application/json',
//   "Authorization": `Bearer ${userToken}`
// }

// Get Packages
export const getPackages = async () => {
  let userToken = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).token : ''
  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${userToken}`
  }
  let packageDetails = {
    packageLabel: '',
    packages: {
      sid: '',
      name: '',
      price: '',
      imageUrl: '',
      vehicleType: '',
      infos: [],
      washInfos: [],
      societyId: '',
    }
  }

  await axios.get(`${process.env.REACT_APP_BASE_URL}/packages`, { headers })
    .then(res => {
      if (res.data) {
        const packageList = res.data.data.packagesList[0];
        const carPackages = packageList.packages[0]
        const bikePackages = res.data.data.packagesList[1].packages

        let bikePackage = bikePackages.map(bikePackage => {
          return {
            packageLabel: res.data.data.packagesList[1].packageLabel,
            packages: {
              sid: bikePackage.sid,
              name: bikePackage.name,
              price: bikePackage.price,
              imageUrl: bikePackage.imageUrl,
              vehicleType: bikePackage.vehicleType,
              infos: bikePackage.infos,
              washInfos: bikePackage.washInfos,
              societyId: bikePackage.societyId,
            }
          }
        })
        packageDetails = {
          carPackage: {
            packageLabel: packageList.packageLabel,
            packages: {
              sid: carPackages.sid,
              name: carPackages.name,
              price: carPackages.price,
              imageUrl: carPackages.imageUrl,
              vehicleType: carPackages.vehicleType,
              infos: carPackages.infos,
              washInfos: carPackages.washInfos,
              societyId: carPackages.societyId,
            }
          },
          bikePackage:{
            packageLabel:  res.data.data.packagesList[1].packageLabel,
            packages: bikePackage
          }
        }

      }
    })
    .catch(err => console.log(err))

  return packageDetails

}

// Get Config
export const getConfig = async (configBody) => {
  let userToken = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).token : ''
  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/json',
    "Authorization": `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI2MzAzOTcwMTEwIiwicm9sZSI6W3siYXV0aG9yaXR5IjoiVVNFUiJ9XSwidXNlcklkIjoiODY4OGNmMGItOGZmMC00ODA0LTg0ZjktYjdmZjUwMGI1NmNlIiwiaWF0IjoxNjEwOTA0ODE0fQ.oYGt9HAanJHFpCax6WIrHpYXu5jHcR8-4DqenOXMRCBnpKBdMNoeHod2yFjK_xNpduva3Ou3YPlDxrj7ynaI6g`
  }
  let responseConfig;

  await axios.post(`${process.env.REACT_APP_BASE_URL}/config`, JSON.stringify(configBody), { headers })
    .then(res => { responseConfig = res.data })
    .catch(err => console.log(err))

  return responseConfig;
}


// get Banners
export const getBanner = async () => {
  let userToken = JSON.parse(sessionStorage.getItem("user")) ? JSON.parse(sessionStorage.getItem("user")).token : ''
  const headers = {
    "Access-Control-Allow-origin": "*",
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${userToken}`
  }

  let resBanner;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/banners`, { headers })
    .then(res => { resBanner = res.data })
    .catch(err => console.log(err))

  return resBanner;
}